import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pt from 'vuetify/lib/locale/pt'
import '@mdi/font/css/materialdesignicons.css'
import PixIcon from '@components/graph/PixIcon'

Vue.use(Vuetify)

const options = {
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: '#ffffff',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        badge: '#000000',
        overlay: '#000000',
        overlayText: '#FFFFFF',
        mainmenu: '#1E1E1E',
        graphColor: '#80D8FF',
        slideCustom: '#212121',
        containerBG: '#000000',
      },
      light: {
        primary: '#000000',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        badge: '#FFFFFF',
        overlay: '#FFFFFF',
        overlayText: '#000000',
        mainmenu: '#000000',
        graphColor: '#464646',
        slideCustom: '#eaebef',
        containerBG: '#F2F2F7',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
    values: {
      pixicon: {
        component: PixIcon,
      },
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
}

Vue.directive('blur', {
  inserted(el) {
    // eslint-disable-next-line no-param-reassign
    el.onfocus = ev => ev.target.blur()
  },
})

Vue.directive('focus', {
  inserted(el) {
    el.focus()
  },
})

export default new Vuetify(options)
