/* eslint-disable no-restricted-globals */
import ability from '@core/plugins/acl/ability'
import jwtDecode from 'jwt-decode'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axios = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axios, jwtOverrideConfig) {
    this.axios = axios
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axios.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        if (config.url.startsWith('/') && !config.url.startsWith('//')) {
          // eslint-disable-next-line no-param-reassign
          config.url = `${jwtDefaultConfig.HOSTNAME}${config.url}`
        }

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axios.interceptors.response.use(
      response => response,
      error => {
        const { config, response } = error
        const originalRequest = config

        if (response && [401, 403, 500].includes(response.status) && response.data.message === 'JWT expired' && this.getRefreshToken()) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(session => {
              this.isAlreadyFetchingAccessToken = false

              this.onAccessTokenFetched(session.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })

          return retryOriginalRequest
        }

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  async getSession() {
    if (!this.getToken()) return false

    try {
      const accessToken = this.getToken()
      const response = jwtDecode(accessToken)
      ability.update(response.user.ability)

      return response
    } catch (err) {
      return false
    }
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  async login(...args) {
    const response = await this.axios.post(this.jwtConfig.loginEndpoint, ...args)
    this.setToken(response.data.accessToken)
    this.setRefreshToken(response.data.refreshToken)

    return Promise.resolve(response)
  }

  hashAuth(...args) {
    return this.axios.post(this.jwtConfig.hashEndpoint, ...args)
  }

  register(...args) {
    return this.axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  async refreshToken() {
    try {
      const refreshToken = this.getRefreshToken()
      const response = await this.axios.post(this.jwtConfig.refreshEndpoint, { refreshToken })

      this.setToken(response.data.accessToken)
      this.setRefreshToken(response.data.refreshToken)

      return response
    } catch (err) {
      localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)

      location.href = '/'
      return Promise.reject(err)
    }
  }

  async logout() {
    const session = await this.getSession()

    if (session) {
      return this.axios.post(this.jwtConfig.logoutEndpoint, { session })
    }
    return false
  }
}
