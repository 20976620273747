/* eslint-disable vue/multi-word-component-names */
import Vue from 'vue'
import SideContent from '@ui/SideContent'
import DatePicker from '@ui/DatePicker'
import SelectField from '@ui/SelectField'
import ListItem from '@ui/ListItem'
import Loading from '@ui/Loading.vue'
import TitleValue from '@ui/TitleValue'
import OverlayEmpty from '@components/ui/OverlayEmpty'
import GlobalToolbar from '@ui/GlobalToolbar'
import HelpTooltip from '@components/ui/HelpTooltip'
import DownloadFile from '@ui/DownloadFile'

Vue.component('side-content', SideContent)
Vue.component('select-field', SelectField)
Vue.component('date-picker', DatePicker)
Vue.component('list-item', ListItem)
Vue.component('title-value', TitleValue)
Vue.component('loading', Loading)
Vue.component('overlay-empty', OverlayEmpty)
Vue.component('global-toolbar', GlobalToolbar)
Vue.component('help-tooltip', HelpTooltip)
Vue.component('download-file', DownloadFile)
