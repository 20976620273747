export default [
  {
    path: 'modelos/criar',
    name: 'ModelNew',
    component: () => import(/* webpackChunkName: "Models" */'./New.vue'),
    meta: { title: 'Criar Modelo', subject: 'Model', action: 'create' },
  },
  {
    path: 'modelos/:modelId/editar',
    name: 'ModelEdit',
    component: () => import(/* webpackChunkName: "Models" */'./Edit.vue'),
    meta: {
      title: 'Editar Modelo',
      subject: 'Model',
      action: 'update',
      newTitle: 'Adicionar modelo',
      newRoute: 'ModelNew',
    },
  },
  {
    path: 'modelos',
    name: 'Models',
    component: () => import(/* webpackChunkName: "Models" */ './Index.vue'),
    meta: {
      title: 'Modelos',
      subject: 'Model',
      action: 'list',
      newTitle: 'Adicionar modelo',
      newRoute: 'ModelNew',
    },
    children: [
      {
        path: ':modelId',
        name: 'ModelShow',
        component: () => import(/* webpackChunkName: "Models" */'./Show.vue'),
        meta: {
          title: 'Exibir Modelo',
          subject: 'Model',
          action: 'read',
          newTitle: 'Adicionar modelo',
          newRoute: 'ModelNew',
        },
      },
    ],
  },
]
