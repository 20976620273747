// import { RequireAutorization } from './routerAuth'

export default {
  name: 'Billings',
  path: '/faturamento/:period',
  component: () => import(/* webpackChunkName: "Billings" */ '@apps/billings/Index.vue'),
  meta: { title: 'Faturamento', subject: 'Billing', action: 'list' },
  children: [
    {
      path: ':id/',
      name: 'BillingsShow',
      component: () => import(/* webpackChunkName: "Billings" */'@apps/billings/Show.vue'),
      meta: { subject: 'Billing', action: 'read' },
    },
  ],
  // beforeEnter: RequireAutorization
}
