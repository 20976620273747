/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
export default {
  path: '/negativacao',
  component: () => import(/* webpackChunkName: "Negativation" */ './Layout.vue'),
  children: [
    {
      name: 'NegativationBatch',
      path: 'lotes',
      component: () => import(/* webpackChunkName: "Negativation" */ './Batch.vue'),
      meta: { title: 'Negativação - Lotes', show: true, subject: 'Negativation', action: 'batch' },
      children: [
        {
          path: 'retorno',
          name: 'NegativationBatchUpload',
          component: () => import(/* webpackChunkName: "Negativation" */'./BatchUpload.vue'),
          meta: { title: 'Processar Retorno', subject: 'Negativation', action: 'batch-upload' },
        },
      ],
    },
    {
      name: 'NegativationGroup',
      path: 'grupos',
      component: () => import(/* webpackChunkName: "NegativationGroup" */ './GroupIndex.vue'),
      meta: { title: 'Grupos de Negativação', show: true, subject: 'NegativationGroup', action: 'list' },
      children: [
        {
          path: 'criar',
          name: 'NegativationGroupNew',
          component: () => import(/* webpackChunkName: "NegativationGroup" */'./GroupNew.vue'),
          meta: { title: 'Adicionar grupo de negativação', subject: 'NegativationGroup', action: 'create' },
        },
        {
          path: ':negativationGroupId/editar',
          name: 'NegativationGroupEdit',
          component: () => import(/* webpackChunkName: "NegativationGroup" */'./GroupEdit.vue'),
          meta: { title: 'Editar grupo de negativação', subject: 'NegativationGroup', action: 'update' },
        },
      ],
    },
    {
      path: 'grupos/:negativationGroupId',
      name: 'NegativationGroupShow',
      component: () => import(/* webpackChunkName: "NegativationGroup" */'./GroupShow.vue'),
      meta: { title: 'Grupo de Negativação', routeShow: 'NegativationGroupShowNegativation', show: false, subject: 'NegativationGroup', action: 'read' },
      children: [
        {
          path: ':negativationId',
          name: 'NegativationGroupShowNegativation',
          component: () => import(/* webpackChunkName: "NegativationGroup" */'./Show.vue'),
          meta: { title: 'Exibir negativação', closeRoute: 'NegativationGroupShow', show: false, subject: 'Negativation', action: 'read' },
        },
      ],
    },
    {
      name: 'Negativation',
      path: '',
      component: () => import(/* webpackChunkName: "Negativation" */ './Index.vue'),
      meta: { title: 'Negativações', routeShow: 'NegativationShow', show: true, subject: 'Negativation', action: 'list' },
      children: [
        {
          path: ':negativationId',
          name: 'NegativationShow',
          component: () => import(/* webpackChunkName: "NegativationGroup" */'./Show.vue'),
          meta: { title: 'Exibir negativação', closeRoute: 'Negativation', show: false, subject: 'Negativation', action: 'read' },
        },
      ],
    },
  ],
}
