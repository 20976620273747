export default {
  path: '/cobrancas',
  name: 'Charges',
  component: () => import(/* webpackChunkName: "Charges" */ './Index.vue'),
  meta: {
    title: 'Cobranças',
    routeShow: 'ChargesShow',
    subject: 'Charge',
    action: 'list',
    newTitle: 'Adicionar Contato',
    newRoute: 'ChargesNew',
  },
  children: [
    {
      path: 'criar',
      name: 'ChargesNew',
      component: () => import(/* webpackChunkName: "Charges" */'./New.vue'),
      meta: { title: 'Criar contato', newTitle: 'Adicionar Cobrança' },
    },
    {
      path: ':chargeId',
      name: 'ChargesShow',
      component: () => import(/* webpackChunkName: "Charges" */'./Show.vue'),
      meta: {
        title: 'Exibir cobrança',
        closeRoute: 'Charges',
        subject: 'Charge',
        action: 'read',
      },
    },
  ],
}
