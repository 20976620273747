export default {
  data: () => ({
    snackbar: {
      show: false,
      color: 'success',
      text: '',
      timeout: 8000,
      vertical: false,
      multiline: false,
      top: true,
      left: false,
      bottom: false,
      right: false,
    },
  }),
}
