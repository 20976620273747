export default {
  path: '/descobertas',
  component: () => import(/* webpackChunkName: "Discoveries" */'@core/layout/LayoutClean.vue'),
  children: [
    {
      path: ':type',
      name: 'Discoveries',
      component: () => import(/* webpackChunkName: "Discoveries" */'./Index.vue'),
      meta: {
        title: 'Descobertas',
        subject: 'Discovery',
        action: 'list',
        newTitle: 'Nova descoberta',
        newRoute: 'DiscoveryNew',
        routeShow: 'DiscoveryShow',
      },
      children: [
        {
          path: 'criar',
          name: 'DiscoveryNew',
          component: () => import(/* webpackChunkName: "Discoveries" */'./New.vue'),
          meta: { title: 'Criar descoberta', subject: 'Discovery', action: 'create' },
        },
      ],
    },
    {
      path: ':type/:discoveryId',
      name: 'DiscoveryShow',
      component: () => import(/* webpackChunkName: "Discoveries" */'./Show.vue'),
      meta: { title: 'Relatório da descoberta', subject: 'Discovery', action: 'read' },
    },
  ],
}
