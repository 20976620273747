import Vue from 'vue'
import { icons } from '@/utils/icons'

const setIcons = instance => {
  instance.mixin({
    data() {
      return {
        icons,
      }
    },
  })
}

Vue.use(setIcons)
