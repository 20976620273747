import Vue from 'vue'
import VueRouter from 'vue-router'
import ROUTE_CONFIGS from '@apps/configs/router'
import ROUTE_ONBOARDING from '@apps/onboarding/router'
import ROUTE_DASHBOARD from '@apps/dashboard/router'
import ROUTE_FINANCIAL from '@apps/financial/router'
// import ROUTE_DEPARTMENTS from '@apps/configs/departments/router'
// import ROUTE_UNITS from '@apps/configs/units/router'
// import ROUTE_USERS from '@apps/configs/users/router'
import ROUTE_PROTESTS from '@apps/protests/router'
import ROUTE_NEGATIVATION from '@apps/negativations/router'
import ROUTE_PROTESTSBOOK from '@apps/protestsBook/router'
import ROUTE_BILLINGS from '@apps/billings/router'
import ROUTE_CONTACTS from '@apps/contacts/router'
import ROUTE_ENRICHMENTS from '@apps/enrichments/router'
import ROUTE_SCHEDULE from '@apps/schedule/router'
import ROUTE_REPORTS from '@apps/reports/router'
import ROUTE_NEGOTIATORS from '@apps/negotiators/router'
import ROUTE_CONCILIATORS from '@apps/conciliators/router'
import ROUTE_LAWSUIT from '@apps/lawsuit/router'
import ROUTE_CAMPAIGNS from '@apps/campaigns/router'
import ROUTE_CHARGES from '@apps/charges/router'
import ROUTE_CASES from '@apps/cases/router'
import ROUTE_DEBTS from '@apps/debts/router'
import ROUTE_BATCHES from '@apps/batches/router'
import ROUTE_DOCUMENTS from '@apps/documents/router'
import ROUTE_AUDIT from '@apps/audit/router'
import ROUTE_DRIVES from '@apps/drives/router'
import ROUTE_PAYMENT from '@apps/payment/router'
import ROUTE_BILLS from '@apps/bills/router'
import ROUTE_TAXES from '@apps/taxes/router'
import ROUTE_CDAS from '@apps/cdas/router'
import ROUTE_VEHICLES from '@apps/vehicles/router'
import ROUTE_DISCOVERIES from '@apps/discoveries/router'
import ROUTE_NOT_AUTHORIZED from '@core/router/notAuthorized'

import ROUTE_BEFORE_EACH from '@core/router/beforeEach'

import ROUTE_NOT_AUTHENTICATED from './notAuthenticated'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "CasesRoute" */'@core/layout/Authenticated.vue'),
    // beforeEnter: RequireAutorization,
    children: [
      ROUTE_CONFIGS,
      ROUTE_DASHBOARD,
      ROUTE_FINANCIAL,
      // ROUTE_DEPARTMENTS,
      // ROUTE_UNITS,
      // ROUTE_USERS,
      ROUTE_PROTESTS,
      ROUTE_PROTESTSBOOK,
      ROUTE_NEGATIVATION,
      ROUTE_CONTACTS,
      ROUTE_ENRICHMENTS,
      ROUTE_BILLINGS,
      ROUTE_SCHEDULE,
      ...ROUTE_REPORTS,
      ROUTE_NEGOTIATORS,
      ROUTE_CONCILIATORS,
      ROUTE_LAWSUIT,
      ROUTE_CAMPAIGNS,
      ROUTE_CHARGES,
      ROUTE_TAXES,
      ROUTE_CDAS,
      ROUTE_CASES,
      ROUTE_DEBTS,
      ROUTE_BATCHES,
      ROUTE_DOCUMENTS,
      ROUTE_AUDIT,
      ROUTE_DRIVES,
      ROUTE_PAYMENT,
      ROUTE_NOT_AUTHORIZED,
      ROUTE_BILLS,
      ROUTE_VEHICLES,
      ROUTE_DISCOVERIES,
    ],
  },
  ROUTE_ONBOARDING,
  ...ROUTE_NOT_AUTHENTICATED,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(ROUTE_BEFORE_EACH)

export default router
