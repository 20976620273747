/* eslint-disable object-curly-newline */
export default {
  path: '/contas',
  component: () => import(/* webpackChunkName: "Bills" */'./Layout.vue'),
  children: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "Bills" */'./Index.vue'),
      name: 'Bills',
      meta: { title: 'Contas', subject: 'Bills', action: 'list', newTitle: 'Adicionar Conta', newRoute: 'BillsNew' },
      children: [
        {
          path: 'criar-multiplos',
          component: () => import(/* webpackChunkName: "Bills" */'./NewMultiple.vue'),
          name: 'BillsNewMultiple',
          meta: { title: 'Criar múltiplas contas', subject: 'Bills', action: 'multi-create' },
        },
      ],
    },
    {
      path: 'criar',
      component: () => import(/* webpackChunkName: "Bills" */'./New.vue'),
      name: 'BillsNew',
      meta: { title: 'Criar conta', subject: 'Bills', action: 'create' },
    },
    {
      path: ':billId',
      component: () => import(/* webpackChunkName: "Bills" */'./New.vue'),
      name: 'BillsEdit',
      meta: { title: 'Atualizar conta', action: 'read' },
    },
  ],
}
