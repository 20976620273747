/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
export default {
  path: 'protestos',
  component: () => import(/* webpackChunkName: "Protest" */ './Layout.vue'),
  children: [
    {
      name: 'ProtestGroup',
      path: 'grupos',
      component: () => import(/* webpackChunkName: "ProtestGroup" */ './GroupIndex.vue'),
      meta: { title: 'Filtros de Protestos', show: true, subject: 'ProtestGroup', action: 'list' },
      children: [
        {
          path: 'criar',
          name: 'ProtestGroupNew',
          component: () => import(/* webpackChunkName: "ProtestGroup" */'./GroupNew.vue'),
          meta: { title: 'Adicionar grupo de protesto', subject: 'ProtestGroup', action: 'create' },
        },
      ],
    },
    {
      path: 'grupos/:protestGroupId',
      name: 'ProtestGroupShow',
      component: () => import(/* webpackChunkName: "ProtestGroup" */'./GroupShow.vue'),
      meta: { title: 'Protestos - Visualizar Filtro', routeShow: 'ProtestGroupShowProtest', show: false, subject: 'ProtestGroup', action: 'read' },
      children: [
        {
          path: ':protestId',
          name: 'ProtestGroupShowProtest',
          component: () => import(/* webpackChunkName: "ProtestGroup" */'./Show.vue'),
          meta: { title: 'Exibir protesto', closeRoute: 'ProtestGroupShow', show: false, subject: 'Protest', action: 'read' },
        },
      ],
    },
    {
      name: 'ProtestBatch',
      path: 'lotes',
      component: () => import(/* webpackChunkName: "ProtestGroup" */ './BatchIndex.vue'),
      meta: { title: 'Lotes para Protesto', show: true, subject: 'ProtestBatch', action: 'list' },
      children: [
        {
          path: 'criar',
          name: 'ProtestGroupNew',
          component: () => import(/* webpackChunkName: "ProtestGroup" */'./GroupNew.vue'),
          meta: { title: 'Adicionar grupo de protesto', subject: 'ProtestGroup', action: 'create' },
        },
      ],
    },
    {
      path: 'lotes/:protestBatchId',
      name: 'ProtestBatchShow',
      component: () => import(/* webpackChunkName: "ProtestGroup" */'./BatchShow.vue'),
      meta: { title: 'Visualizar Lote', routeShow: 'ProtestGroupShowProtest', show: false, subject: 'ProtestBatch', action: 'read' },
      children: [
        {
          path: ':protestId',
          name: 'ProtestGroupShowProtest',
          component: () => import(/* webpackChunkName: "ProtestGroup" */'./Show.vue'),
          meta: { title: 'Exibir protesto', closeRoute: 'ProtestGroupShow', show: false, subject: 'Protest', action: 'read' },
        },
      ],
    },
    {
      name: 'Protest',
      path: '',
      component: () => import(/* webpackChunkName: "Protest" */ './Index.vue'),
      meta: { title: 'Protestos', routeShow: 'ProtestShow', show: true, subject: 'Protest', action: 'list' },
      children: [
        {
          path: ':protestId',
          name: 'ProtestShow',
          component: () => import(/* webpackChunkName: "Protest" */'./Show.vue'),
          meta: { title: 'Exibir protesto', closeRoute: 'Protests', show: false, subject: 'Protest', action: 'read' },
        },
      ],
    },
  ],
}
