<template>
  <v-select
    v-model="selected"
    :items="items"
    :menu-props="{ maxHeight: maxHeight }"
    class="mb-4"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    hide-details
    hide-label
    dense
    filled
    rounded
    :multiple="multiple"
    @change="$emit('change', $event)"/>
</template>

<script>
export default {
  name: 'SelectField',
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    itemText: {
      type: String,
      default: 'name',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: '500',
    },
    items: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  data: () => ({
    selected: [],
  }),
}
</script>
