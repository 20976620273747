<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="mb-4"
        :value="dateFormated"
        :label="label"
        :rules="rules"
        readonly
        filled
        dense
        flat
        clearable
        hide-details
        v-bind="attrs"
        v-on="on"
        @click:clear="save('')"/>
    </template>
    <v-date-picker
      v-model="date"
      :active-picker.sync="activePicke"
      :max="max"
      :min="min"
      @change="save"/>
  </v-menu>
</template>

<script>
export default {
  name: 'UiDatePicker',
  props: {
    rules: {
      type: Array,
    },
    label: {
      type: String,
      default: '',
      required: true,
    },
    max: {
      type: String,
      default: () => (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    },
    min: {
      type: String,
      default: '1950-01-01',
    },
  },
  data: () => ({
    activePicke: null,
    date: '',
    menu: false,
  }),
  computed: {
    dateFormated() {
      return this.date ? this.date.split('-').reverse().join('/') : ''
    },
  },
  watch: {
    menu(val) {
      // eslint-disable-next-line no-return-assign
      if (val) this.$nextTick(() => (this.activePicke = 'YEAR'))
    },
  },
  methods: {
    save(value) {
      this.date = value
      this.$refs.menu.save(value)
      this.$emit('change', value)
    },
  },
}
</script>
