// import { RequireAutorization } from './routerAuth'

export default {
  path: '/acionamentos',
  component: () => import(/* webpackChunkName: "Drives" */ './Layout.vue'),
  // beforeEnter: RequireAutorization
  children: [
    {
      path: 'cartas',
      name: 'DriveLetters',
      component: () => import(/* webpackChunkName: "Drives" */'./Letters.vue'),
      meta: { subject: 'Drive', action: 'letter' },
      children: [
        {
          path: ':id',
          name: 'DriveLettersShow',
          component: () => import(/* webpackChunkName: "Drives" */'./LettersShow.vue'),
          meta: { subject: 'Drive', action: 'letter' },
        },
      ],
    },
    {
      path: 'emails',
      name: 'DriveEmails',
      component: () => import(/* webpackChunkName: "Drives" */'./Emails.vue'),
      meta: { subject: 'Drive', action: 'email' },
      children: [
        {
          path: ':id',
          name: 'DriveEmailsShow',
          component: () => import(/* webpackChunkName: "Drives" */'./EmailsShow.vue'),
          meta: { subject: 'Drive', action: 'email' },
        },
      ],
    },
  ],
}
