/* eslint-disable no-restricted-globals */
const HOSTNAME = process.env.VUE_APP_HOST_API || 'localhost:3000'

export default {
  HOSTNAME,
  // Endpoints
  preLoginEndpoint: '/auth/pre',
  loginEndpoint: '/auth',
  sessionEndpoint: '/auth/session',
  hashEndpoint: '/auth/authenticate',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/auth/refresh',
  logoutEndpoint: '/auth/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
