// import { RequireAutorization } from './routerAuth'

export default {
  path: '/agenda',
  component: () => import(/* webpackChunkName: "Schedule" */ './Layout.vue'),
  // beforeEnter: RequireAutorization
  children: [
    {
      path: '/',
      name: 'Schedule',
      component: () => import(/* webpackChunkName: "Schedule" */'./Today.vue'),
      meta: { title: '$vuetify.schedules.title', subject: 'Schedule', action: 'today' },
    },
    {
      path: 'proximos',
      name: 'Scheduled',
      component: () => import(/* webpackChunkName: "Schedule" */'./Scheduled.vue'),
      meta: { title: '$vuetify.schedules.title', subject: 'Schedule', action: 'future' },
    },
    {
      path: 'concluidos',
      name: 'ScheduleCompleted',
      component: () => import(/* webpackChunkName: "Schedule" */'./Completed.vue'),
      meta: { title: '$vuetify.schedules.title', subject: 'Schedule', action: 'completed' },
    },
    {
      path: 'jitsi',
      name: 'JitsiContainer',
      component: () => import(/* webpackChunkName: "Schedule" */'./JitsiContainer.vue'),
    },
    {
      path: ':scheduleId/videochamada',
      name: 'VideoCall',
      component: () => import(/* webpackChunkName: "Schedule" */'./VideoCall.vue'),
      meta: { title: 'Videochamada', subject: 'Schedule', action: 'video' },
      children: [
        {
          path: 'termo/criar',
          name: 'VideoCallNewTerm',
          component: () => import(/* webpackChunkName: "Schedule" */'./NewTerm.vue'),
          meta: { title: 'Criar Termo', subject: 'Schedule', action: 'video-new-term' },
        },
        {
          path: 'termo/editar',
          name: 'VideoCallEditTerm',
          component: () => import(/* webpackChunkName: "Schedule" */'./EditTerm.vue'),
          meta: { title: 'Editar Termo', subject: 'Schedule', action: 'video-edit-term' },
        },
        {
          path: 'termo/enviar-assinatura',
          name: 'VideoCallUploadToSign',
          component: () => import(/* webpackChunkName: "Schedule" */'./TermUploadToSign.vue'),
          meta: { title: 'Enviar para Assinatura', subject: 'Schedule', action: 'video-new-term' },
        },
      ],
    },
    {
      path: 'calendario',
      name: 'ScheduleCalendar',
      component: () => import(/* webpackChunkName: "Schedule" */'./Calendar.vue'),
      meta: { title: 'Calendário', subject: 'Schedule', action: 'calendar' },
    },
  ],
}
