import Vue from 'vue'
import VueSocketIO from '@core/socket'

Vue.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_HOST_API,
  // vuex: {
  //   store,
  //   actionPrefix: 'SOCKET_',
  //   mutationPrefix: 'SOCKET_',
  // },
  options: {
    reconnect: true,
    transports: ['websocket'],
    // path: '/my-app/'
  },
}))

export default VueSocketIO
