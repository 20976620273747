// import { RequireAutorization } from './routerAuth'

export default {
  path: '/contatos',
  component: () => import(/* webpackChunkName: "Contacts" */ '@core/layout/LayoutClean.vue'),
  children: [
    {
      path: 'criar-multiplos',
      name: 'ContactsNewMultiple',
      component: () => import(/* webpackChunkName: "Contacts" */'./NewMultiple.vue'),
      meta: { title: 'Criar múltiplos contatos', subject: 'Contact', action: 'multi-create' },
    },
    {
      path: 'criar-multiplos/:importId/enriquecer',
      name: 'ContactsNewMultipleEnrichment',
      component: () => import(/* webpackChunkName: "Contacts" */'./NewMultipleEnrichment.vue'),
      meta: { title: 'Enriquecer múltiplos contatos' },
    },
    {
      path: 'filtros',
      name: 'ContactsFilters',
      component: () => import(/* webpackChunkName: "ContactsFilters" */'./FiltersIndex.vue'),
      meta: {
        title: 'Filtros de',
        show: true,
        subject: 'Contact',
        action: 'group-list',
        newTitle: 'Adicionar Filtro',
        newRoute: 'ContactsFiltersNew',
      },
    },
    {
      path: 'criar-filtro',
      name: 'ContactsFiltersNew',
      component: () => import(/* webpackChunkName: "ContactsFilters" */'./FilterNew.vue'),
      meta: {
        title: 'Adicionar filtro',
        routeShow: 'ContactsShow',
        subject: 'Contact',
        action: 'group-create',
      },
    },
    {
      path: 'criar',
      name: 'ContactsNew',
      component: () => import(/* webpackChunkName: "ContactsFilters" */'./New.vue'),
      meta: {
        title: 'Criar contato',
        routeShow: 'ContactsShow',
        subject: 'Contact',
        action: 'contact-create',
      },
    },
    {
      path: 'filtros/:contactsFilterId/editar',
      name: 'ContactsFiltersEdit',
      component: () => import(/* webpackChunkName: "ContactsFilters" */'./FilterEdit.vue'),
      meta: { title: 'Editar filtro', subject: 'Contacts', action: 'group-update' },
    },
    {
      path: 'filtros/:contactsFilterId',
      name: 'ContactsFilterShow',
      component: () => import(/* webpackChunkName: "ContactsFilters" */'./FilterShow.vue'),
      meta: {
        title: 'Visualizar Filtro',
        routeShow: 'ContactsShow',
        show: false,
        subject: 'Contact',
        action: 'group-read',
      },
      children: [
        {
          path: ':contactId',
          name: 'ContactsFilterShowContact',
          component: () => import(/* webpackChunkName: "ContactsFilters" */'./Show.vue'),
          meta: {
            title: 'Exibir contato',
            closeRoute: 'ContactsFilterShow',
            show: false,
            subject: 'Contact',
            action: 'group-read',
          },
        },
      ],
    },
    {
      path: '/',
      name: 'Contacts',
      component: () => import(/* webpackChunkName: "Contacts" */'./Index.vue'),
      meta: {
        title: 'Contatos',
        routeShow: 'ContactsShow',
        subject: 'Contact',
        action: 'list',
        newTitle: 'Adicionar Contato',
        newRoute: 'ContactsNew',
      },
      children: [
        {
          path: 'criar',
          name: 'ContactsNewSideContent',
          component: () => import(/* webpackChunkName: "Contacts" */'./NewSideContent.vue'),
          meta: { title: 'Criar contato', newTitle: 'Adicionar Contato' },
        },
        {
          path: ':contactId/editar',
          name: 'ContactsEdit',
          component: () => import(/* webpackChunkName: "Contacts" */'./Edit.vue'),
          meta: { title: 'Contatos' },
        },
        {
          path: ':contactId/titulos/:debtId/editar',
          name: 'ContactsEditDebt',
          component: () => import(/* webpackChunkName: "Contacts" */'./EditDebt.vue'),
          meta: { title: 'Editar título' },
        },
      ],
    },
    {
      path: ':contactId',
      name: 'ContactsShow',
      component: () => import(/* webpackChunkName: "Contacts" */'./Show.vue'),
      meta: {
        title: 'Exibir contato',
        closeRoute: 'Contacts',
        subject: 'Contact',
        action: 'read',
      },
      children: [
        {
          path: 'criar-titulo',
          name: 'ContactsNewDebt',
          component: () => import(/* webpackChunkName: "Contacts" */'./NewDebt.vue'),
          meta: { title: 'Adicionar título' },
        },
      ],
    },
  ],
  // beforeEnter: RequireAutorization
}
