// import { RequireAutorization } from './routerAuth'

export default {
  name: '',
  path: '/onboarding',
  component: () => import(/* webpackChunkName: "Onboarding" */'@/layout/Onboarding.vue'),
  // beforeEnter: RequireAutorization
  children: [
    {
      path: '',
      name: 'OnboardingInit',
      component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/Index.vue'),
    },
    {
      path: 'termos-de-uso',
      name: 'OnboardingTerms',
      component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/Terms.vue'),
    },
    {
      path: 'departamentos',
      name: 'OnboardingDepartments',
      component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/Departments.vue'),
    },
    {
      path: 'unidades',
      name: 'OnboardingUnits',
      component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/Units.vue'),
    },
    {
      path: 'recebimento',
      name: 'OnboardingReceiptAccount',
      component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/ReceiptAccount.vue'),
    },
    {
      path: 'reembolso',
      name: 'OnboardingReimbursement',
      component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/Reimbursement.vue'),
    },
    {
      path: 'uso-da-plataforma',
      name: 'OnboardingPlataformUse',
      component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/PlataformUse.vue'),
    },
    {
      path: 'tipos-de-consulta',
      name: 'OnboardingQueryTypes',
      component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/QueryTypes.vue'),
    },
    {
      path: 'regras-de-parcelamento',
      name: 'OnboardingInstallmentRules',
      component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/InstallmentRules.vue'),
    },
    {
      path: 'acrescimos-legais',
      name: 'OnboardingLegalAdditions',
      component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/LegalAdditions.vue'),
    },
    // {
    //   path: 'importacao',
    //   name: 'OnboardingDataImport',
    //   component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/DataImport.vue'),
    // },
    // {
    //   path: 'enriquecimento',
    //   name: 'OnboardingDataEnrichment',
    //   component: () => import(/* webpackChunkName: "Onboarding" */'@apps/onboarding/DataEnrichment.vue'),
    // },
  ],
}
