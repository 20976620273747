import Vue from 'vue'
import './registerServiceWorker'
import pinia from '@plugins/pinia'
import vuetify from '@plugins/vuetify'
import * as Sentry from '@sentry/vue'
import useJwt from '@core/auth/jwt/useJwt'
import router from './router'
import App from './App.vue'
import '@/plugins/components'
import '@/plugins/icons'
import '@/plugins/perfect-scrollbar'
import '@/plugins/portal'
import '@/plugins/vjsf'
import '@/plugins/moment'
import '@/plugins/anime'
import '@core/plugins/money'
import '@core/plugins/numeral'
import '@core/plugins/supabase'
import '@core/plugins/axios'
import '@core/plugins/acl'
import '@/plugins/socket'
import '@/plugins/mask'
import '@/plugins/logger'

Sentry.init({
  Vue,
  dsn: 'https://78a62cd31edc49c29688c4ee6ce326f0@glitch.modera.app/2',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

Vue.config.productionTip = false

useJwt.axios.get('/settings').then(response => {
  if (response.status !== 200) console.log('error')

  const instance = new Vue({
    router,
    pinia,
    vuetify,
    data: () => ({ a: 'a' }),
    render: h => h(App),
  }).$mount('#app')

  const { locales, themes } = response.data
  instance.$vuetify.lang.locales.pt = { ...instance.$vuetify.lang.locales.pt, ...locales }
  instance.$vuetify.theme.themes = themes
})
