import Vue from 'vue'
import VueLogger from 'vue-logger-plugin'

const options = {
  enabled: true,
  level: 'debug',
  // beforeHooks: [ ... ],
  // afterHooks: [ ... ]
}

// export logger with applied options
const logger = new VueLogger(options)

Vue.use(logger)
