export default {
  path: 'cadastro_tributario',
  name: 'Taxes',
  component: () => import(/* webpackChunkName: "Taxes" */ './Index.vue'),
  meta: {
    title: 'Cadastro Tributário',
    subject: 'Taxes',
    action: 'list',
    newTitle: 'Adicionar Cadastro Tributário',
    newRoute: 'TaxNew',
  },
  children: [
    {
      path: 'criar-multiplos',
      name: 'TaxNewMultiple',
      component: () => import(/* webpackChunkName: "Taxes" */'./NewMultiple.vue'),
      meta: { title: 'Múltiplos Cadastro Tributário' },
    },
    {
      path: ':taxId',
      name: 'TaxShow',
      component: () => import(/* webpackChunkName: "Taxes" */'./Show.vue'),
      meta: { title: 'Cadastro Tributário' },
    },
  ],
}
