// import { RequireAutorization } from './routerAuth'

export default {
  name: 'ProtestsBook',
  path: '/livro-protestos',
  component: () => import(/* webpackChunkName: "ProtestsBook" */ '@apps/protestsBook/Index.vue'),
  meta: { title: 'Livro de Protestos', subject: 'ProtestBook', action: 'list' },
  children: [
    {
      path: 'criar',
      name: 'ProtestBookNew',
      component: () => import(/* webpackChunkName: "ProtestsBook" */'@apps/protestsBook/New.vue'),
      meta: { title: 'Adicionar livro de protesto', subject: 'ProtestBook', action: 'create' },
    },
    {
      path: ':protestBookId/editar',
      name: 'ProtestBookEdit',
      component: () => import(/* webpackChunkName: "ProtestsBook" */'@apps/protestsBook/Edit.vue'),
      meta: { title: 'Editar livro de protesto', subject: 'ProtestBook', action: 'update' },
    },
    {
      path: ':protestBookId',
      name: 'ProtestBookShow',
      component: () => import(/* webpackChunkName: "ProtestsBook" */'@apps/protestsBook/Show.vue'),
      meta: { title: 'Exibir livro de protesto', subject: 'ProtestBook', action: 'read' },
    },
  ],
  // beforeEnter: RequireAutorization
}
