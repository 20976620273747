export default {
  path: '/propostas',
  component: () => import(/* webpackChunkName: "proposal" */'./Layout.vue'),
  children: [
    {
      name: 'ContractAccepted',
      path: 'aceito',
      component: () => import(/* webpackChunkName: "proposal" */'./ContractAccepted.vue'),
      meta: { title: 'Mais - Contrato aceito', subject: 'Auth' },
    },
    {
      name: 'ContractRefused',
      path: 'recusado',
      component: () => import(/* webpackChunkName: "proposal" */'./ContractRefused.vue'),
      meta: { title: 'Mais - Contrato recusado', subject: 'Auth' },
    },
    {
      name: 'ContractConfirmation',
      path: ':id',
      component: () => import(/* webpackChunkName: "proposal" */'./ContractConfirmation.vue'),
      meta: { title: 'Mais - Confirmação de Contrato', subject: 'Auth' },
    },
  ],
}
