export default {
  path: '/consultas',
  component: () => import(/* webpackChunkName: "Enrichments" */ './Layout.vue'),
  children: [
    {
      path: '/',
      name: 'Enrichment',
      component: () => import(/* webpackChunkName: "Enrichments" */'./Index.vue'),
      // meta: { title: 'Consultas', subject: 'Enrichment', action: 'list' },
      meta: {
        title: 'Consultas',
        subject: 'Enrichment',
        action: 'list',
        newTitle: 'Nova consulta',
        newRoute: 'EnrichmentNew',
        routeShow: 'EnrichmentShow',
      },
      children: [
        {
          path: 'criar',
          name: 'EnrichmentNew',
          component: () => import(/* webpackChunkName: "Enrichments" */'./New.vue'),
          meta: { title: 'Criar consulta', subject: 'Enrichment', action: 'create' },
        },
      ],
    },
    {
      path: ':enrichmentId',
      name: 'EnrichmentShow',
      component: () => import(/* webpackChunkName: "Enrichments" */'./Show.vue'),
      meta: { title: 'Relatório da consulta', subject: 'Enrichment', action: 'read' },
    },
  ],
}
