import anime from 'animejs'
import Vue from 'vue'

// eslint-disable-next-line no-shadow
const animeMixin = Vue => {
  Vue.mixin({
    data() {
      return {
        anime,
      }
    },
  })
}

Vue.use(animeMixin)
