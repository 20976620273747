<template>
  <v-btn
    :loading="loading"
    :class="classNames"
    :color="color"
    :icon="onlyIcon"
    :small="size === 'small'"
    :x-small="size === 'xSmall'"
    :large="size === 'large'"
    :x-large="size === 'xLarge'"
    :rounded="isMobile"
    :disabled="disabled"
    :depressed="depressed"
    @click="download(path)">
    <span v-if="!onlyIcon && !isMobile">{{ title }}</span>
    <v-icon :right="!onlyIcon && !isMobile" :small="!onlyIcon">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
import useJwt from '@core/auth/jwt/useJwt'

export default {
  name: 'DownloadFile',
  props: {
    path: {
      type: String,
    },
    title: {
      type: String,
      default: 'Baixar',
    },
    size: {
      type: String,
      default: '',
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    depressed: {
      type: Boolean,
      default: false,
    },
    classNames: {
      type: String,
      default: 'ma-2 white--text',
    },
    color: {
      type: String,
      default: 'secondary',
    },
    icon: {
      type: String,
      default: 'mdi-download',
    },
    fileName: {
      type: String,
      default: '',
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async download(filePath) {
      try {
        const filename = this.fileName ? this.fileName : filePath.split('/').pop()
        this.loading = true
        const response = await useJwt.axios({
          url: '/files/download',
          method: 'POST',
          data: { filePath },
          responseType: 'blob',
        })

        const href = URL.createObjectURL(response.data)

        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
