export default {
  path: '/',
  name: 'Dashboard',
  component: () => import(/* webpackChunkName: "Dashboard" */ '@apps/dashboard/Layout.vue'),
  meta: {
    subject: 'Dashboard',
    action: 'read',
    newTitle: 'Adicionar Campanha',
    newRoute: 'CampaignsNew',
  },
  children: [
    // {
    //   path: 'configuracoes/acrescimos-legais',
    //   name: 'AppsConfigsLegalAdditions',
    //   components: {
    //     default: () => import(/* webpackChunkName: "Configs" */ '@apps/dashboard/DashboardEnterprise.vue'),
    //     configs: () => import(/* webpackChunkName: "Configs" */ '@apps/configs/legal/Edit.vue'),
    //   },
    // },
    // {
    //   path: 'configuracoes/camara',
    //   name: 'AppsConfigsCamara',
    //   components: {
    //     default: () => import(/* webpackChunkName: "Configs" */ '@apps/dashboard/DashboardEnterprise.vue'),
    //     configs: () => import(/* webpackChunkName: "Configs" */ '@apps/configs/options/Edit.vue'),
    //   },
    //   meta: { title: 'Configurações para a Câmara' },
    // },
    // {
    //   path: 'configuracoes/fluxo',
    //   name: 'AppsConfigsFlow',
    //   components: {
    //     default: () => import(/* webpackChunkName: "Configs" */ '@apps/dashboard/DashboardEnterprise.vue'),
    //     configs: () => import(/* webpackChunkName: "Configs" */ '@apps/configs/flow/Edit.vue'),
    //   },
    //   meta: { title: 'Fluxo' },
    // },
    // {
    //   path: 'configuracoes/organizacao',
    //   name: 'AppsConfigsOrganizationProfile',
    //   components: {
    //     default: () => import(/* webpackChunkName: "Configs" */ '@apps/dashboard/DashboardEnterprise.vue'),
    //     configs: () => import(/* webpackChunkName: "Configs" */ '@apps/configs/organizationProfile/Edit.vue'),
    //   },
    //   meta: { title: 'Logo' },
    // },
    // {
    //   path: 'configuracoes/documentos',
    //   name: 'AppsConfigsDocuments',
    //   components: {
    //     default: () => import(/* webpackChunkName: "Configs" */ '@apps/dashboard/DashboardEnterprise.vue'),
    //     configs: () => import(/* webpackChunkName: "Configs" */ '@apps/configs/documents/Edit.vue'),
    //   },
    //   meta: { title: 'Documentos da empresa' },
    // },
    // {
    //   path: 'configuracoes/recebimento',
    //   name: 'AppsConfigsReceipt',
    //   components: {
    //     default: () => import(/* webpackChunkName: "Configs" */ '@apps/dashboard/DashboardEnterprise.vue'),
    //     configs: () => import(/* webpackChunkName: "Configs" */ '@apps/configs/receipt/Edit.vue'),
    //   },
    //   meta: { title: 'Recebimento' },
    // },
  ],
}
