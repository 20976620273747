export default {
  path: '/contratos',
  component: () => import(/* webpackChunkName: "contractConfirmation" */'./Layout.vue'),
  children: [
    {
      name: 'SignnaturesConfirmed',
      path: 'confirmada',
      component: () => import(/* webpackChunkName: "SignaturesDocument" */'./Signed.vue'),
      meta: { title: 'Mais - Documento Assinado', subject: 'Auth' },
    },
    {
      name: 'SignaturesRefused',
      path: 'recusado',
      component: () => import(/* webpackChunkName: "SignaturesDocument" */'./Refused.vue'),
      meta: { title: 'Mais - Contrato recusado', subject: 'Auth' },
    },
    {
      name: 'SignaturesDocument',
      path: ':id',
      component: () => import(/* webpackChunkName: "SignaturesDocument" */'./Index.vue'),
      meta: { title: 'Mais - Assinar Documento', subject: 'Auth' },
      children: [
        {
          name: 'SignaturesGetCode',
          path: 'codigo',
          component: () => import(/* webpackChunkName: "SignaturesDocument" */'./GetCode.vue'),
          meta: { title: 'Mais - Documento Assinado', subject: 'Auth' },
        },
        {
          name: 'SignaturesConfirm',
          path: 'confirmar',
          component: () => import(/* webpackChunkName: "SignaturesDocument" */'./CodeConfirm.vue'),
          meta: { title: 'Mais - Documento Assinado', subject: 'Auth' },
        },
      ],
    },
  ],
}
