import { ref } from 'vue'
import { defineStore } from 'pinia'
import useJwt from '@core/auth/jwt/useJwt'

const userId = ref('')
const userName = ref('')
const userAbv = ref('')
const userFirstName = ref('')
const userEmail = ref('')
const userType = ref('')
const userUnit = ref('')
const organization = ref('')
const dashboard = ref('')
const demo = ref('')
const plan = ref('')
const contact = ref('')
const communicationList = ref([])
const isPrePaid = ref('')
const showOnboarding = ref('')

useJwt.getSession().then(session => {
  const { user } = { user: { name: '', ...session.user } }
  userId.value = user.id
  userName.value = user.name
  userAbv.value = user.name.split(' ').map(item => item.substr(0, 1)).join('')
  userFirstName.value = user.name.split(' ').shift()
  userEmail.value = user.email
  userType.value = user.type
  userUnit.value = user.unit
  organization.value = user.organization
  dashboard.value = user.dashboard
  demo.value = user.demo
  plan.value = user.plan
  contact.value = user.contact
  communicationList.value = user.communication
  isPrePaid.value = user.isPrePaid
  showOnboarding.value = user.showOnboarding
})

export default defineStore('app', {
  state: () => ({
    userId,
    userName,
    userAbv,
    userFirstName,
    userEmail,
    userUnit,
    userType,
    organization,
    dashboard,
    demo,
    plan,
    contact,
    communicationList,
    isPrePaid,
    showOnboarding,
    count: {},
    expectedReceipt: [],
    companiesByMonth: [],
    paymentGateway: [],
    paymentGatewayLoading: false,
  }),
  getters: {
    userRef: state => state.contact || state.userId,
  },
  actions: {
    async userChangePassword(data) {
      const response = await useJwt.axios.post('/users/changePassword', data)
      return response
    },
    async statsCount() {
      const response = await useJwt.axios.get('/stats/count')
      this.count = response.data
    },
    async fetchPaymentGateway(data) {
      this.paymentGatewayLoading = true
      const { amount, paymentMethod } = data
      const response = await useJwt.axios.post(`/balances/add${paymentMethod}`, { amount: parseFloat(amount) })
      this.paymentGateway = response.data
      this.paymentGatewayLoading = false
      return response
    },
    async getCompaniesCount() {
      const response = await useJwt.axios.get('/backoffice/dashboard/companiesCount')
      this.companiesCount = response.data.companiesCount
    },
    async getExpectedAmount() {
      const response = await useJwt.axios.get('/backoffice/dashboard/expectedAmount')
      this.expectedAmount = response.data.expectedAmount
    },
    async getExpectedReceipt() {
      const response = await useJwt.axios.get('/backoffice/dashboard/expectedReceipt')
      this.expectedReceipt = response.data
    },
    async getCompaniesByMonth() {
      const response = await useJwt.axios.get('/backoffice/dashboard/companiesByMonth')
      this.companiesByMonth = response.data
    },
  },
})
