<template>
  <v-list-item :dense="dense" :two-line="twoLines">
    <v-list-item-content>
      <v-list-item-title class="font-weight-bold">{{ title  }}</v-list-item-title>
      <v-list-item-subtitle v-if="!dateFormat && !boolean && twoLines" class="font-weight-thin text-wrap">{{ value }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action-text
      v-if="!dateFormat && !boolean && !twoLines"
      class="font-weight-thin text-wrap">{{ value }}
    </v-list-item-action-text>
    <v-list-item-action-text
      v-if="dateFormat && !boolean && !twoLines"
      class="font-weight-thin text-wrap">
      {{ value | moment(dateFormat) }}
    </v-list-item-action-text>
    <v-list-item-action-text v-if="!dateFormat && boolean && !twoLines" class="font-weight-thin text-wrap">
      <v-icon :color="value ? 'black':'grey lighten-1'" dense>mdi-check-circle</v-icon>
    </v-list-item-action-text>
  </v-list-item>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    title: {
      type: String,
    },
    value: {
      type: [String, Number, Boolean],
    },
    dateFormat: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    boolean: {
      type: Boolean,
      default: false,
    },
    twoLines: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass">
  .v-list--dense
    .v-list-item
      min-height: 36px
</style>
