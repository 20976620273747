export default {
  path: 'indices',
  name: 'FinancialIndex',
  component: () => import(/* webpackChunkName: "FinancialIndex" */ './Index.vue'),
  meta: {
    title: 'Índices Financeiros',
    subject: 'FinancialIndex',
    action: 'list',
    newTitle: 'Adicionar indicador',
    newRoute: 'FinancialIndexNew',
  },
  children: [
    {
      path: 'criar',
      name: 'FinancialIndexNew',
      component: () => import(/* webpackChunkName: "FinancialIndex" */'./New.vue'),
      meta: { title: 'Adicionar indicador', newTitle: 'Adicionar Unidade' },
    },
    {
      path: ':indexId',
      name: 'FinancialIndexShow',
      component: () => import(/* webpackChunkName: "FinancialIndex" */'./Show.vue'),
      meta: { title: 'Indicador Econômico' },
    },
  ],
}
