export default {
  path: '/carga',
  component: () => import(/* webpackChunkName: "Contacts" */ '@core/layout/LayoutClean.vue'),
  children: [
    {
      path: '/',
      name: 'Batches',
      component: () => import(/* webpackChunkName: "Batch" */'./Index.vue'),
      meta: { title: 'Carga', subject: 'Batch', action: 'list' },
    },
    {
      path: 'importar',
      name: 'BatchesImport',
      component: () => import(/* webpackChunkName: "Batch" */'./Import.vue'),
      meta: { title: 'Importar', subject: 'Batch', action: 'multi-create' },
    },
  ],
}
