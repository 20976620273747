import axios from '@axios'
import JwtService from './jwtService'

const useJwt = (axiosIns, jwtOverrideConfig) => {
  const jwt = new JwtService(axiosIns, jwtOverrideConfig)

  return { jwt }
}

const { jwt } = useJwt(axios, {})

export default jwt
