export default {
  path: '/veiculos',
  component: () => import(/* webpackChunkName: "Vehicles" */ './Layout.vue'),
  children: [
    {
      path: '/',
      name: 'Vehicle',
      component: () => import(/* webpackChunkName: "Vehicles" */'./Index.vue'),
      meta: {
        title: 'Consultas',
        subject: 'Vehicle',
        action: 'list',
        newTitle: 'Nova consulta',
        newRoute: 'VehicleNew',
        routeShow: 'VehicleShow',
      },
      children: [
        {
          path: 'criar',
          name: 'VehicleNew',
          component: () => import(/* webpackChunkName: "Vehicles" */'./New.vue'),
          meta: { title: 'Criar consulta', subject: 'Vehicle', action: 'create' },
        },
      ],
    },
    {
      path: ':vehicleId',
      name: 'VehicleShow',
      component: () => import(/* webpackChunkName: "Vehicles" */'./Show.vue'),
      meta: { title: 'Relatório da consulta', subject: 'Vehicle', action: 'read' },
    },
    {
      path: ':vehicleId',
      name: 'VehicleShowPlate',
      component: () => import(/* webpackChunkName: "Vehicles" */'./ShowPlate.vue'),
      meta: { title: 'Relatório da consulta', subject: 'Vehicle', action: 'read' },
    },
  ],
}
