// import { RequireAutorization } from './routerAuth'

export default {
  path: '/campanhas',
  component: () => import(/* webpackChunkName: "Campaigns" */ '@core/layout/LayoutClean.vue'),
  children: [
    {
      path: 'criar',
      name: 'CampaignsNew',
      component: () => import(/* webpackChunkName: "Campaigns" */'@apps/campaigns/New.vue'),
      meta: {
        title: 'Adicionar campanha',
        subject: 'Campaign',
        action: 'create',
        newTitle: 'Adicionar Campanha',
      },
    },
    {
      name: 'Campaigns',
      path: '/',
      component: () => import(/* webpackChunkName: "Campaigns" */ '@apps/campaigns/Index.vue'),
      meta: {
        title: 'Campanhas',
        subject: 'Campaign',
        action: 'list',
        newTitle: 'Adicionar Campanha',
        newRoute: 'CampaignsNew',
      },
      // beforeEnter: RequireAutorization
      children: [
        {
          path: 'lote-planilha-email',
          name: 'CampaignsImportBatchEmails',
          component: () => import(/* webpackChunkName: "Campaigns" */'@apps/campaigns/FileBatchEmail.vue'),
          meta: { title: 'Negociação online', params: { isOnline: true } },
        },
        {
          path: ':campaignId/negotiation',
          name: 'CampaignsShowNegotiation',
          component: () => import(/* webpackChunkName: "Campaigns" */'@apps/campaigns/ShowNegotiation.vue'),
          meta: { title: 'Negociação online', params: { isOnline: true } },
        },
        {
          path: ':campaignId/mediation',
          name: 'CampaignsShowMediation',
          component: () => import(/* webpackChunkName: "Campaigns" */'@apps/campaigns/ShowMediation.vue'),
          meta: { title: 'Mediação online' },
        },
      ],
    },
    {
      path: ':campaignId',
      component: () => import(/* webpackChunkName: "Campaigns" */'./LayoutShow.vue'),
      meta: { subject: 'Campaign', action: 'read' },
      children: [
        {
          path: '/',
          name: 'CampaignsShow',
          component: () => import(/* webpackChunkName: "Campaigns" */'./Show.vue'),
          meta: { subject: 'Campaign', action: 'read' },
        },
        {
          path: ':channel',
          name: 'CampaignInviteList',
          component: () => import(/* webpackChunkName: "Campaigns" */'./InviteList.vue'),
          meta: { subject: 'Campaign', action: 'read' },
          children: [
            {
              path: ':id',
              name: 'CampaignInviteShow',
              component: () => import(/* webpackChunkName: "Drives" */'./InviteShow.vue'),
              meta: { subject: 'Drive', action: 'email', routeTo: 'CampaignInviteList' },
            },
          ],
        },
      ],
    },
  ],
}
