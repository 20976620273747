import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(PiniaVuePlugin)

const pinia = createPinia()

pinia.use(({ app, store }) => {
  store.$onAction(({ after, onError }) => {
    after(result => {
      if (result && result.data?.data) app.$root.$emit('snackbar', result.data.data)
    })
    onError(error => app.$root.$emit('snackbar', error.response.data.data))
  })
})

export default pinia
