import ROUTE_HOLIDAYS from '@apps/configs/holidays/router'
import ROUTE_FINANCIAL_INDEX from '@apps/configs/financialIndex/router'
import ROUTE_MODELS from '@apps/configs/models/router'

export default {
  path: 'configuracoes',
  name: 'Configuration',
  component: () => import(/* webpackChunkName: "Configs" */ '@apps/configs/Layout.vue'),
  children: [
    {
      path: 'geral',
      name: 'AppsConfigsGeneral',
      component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/general/Index.vue'),
    },
    {
      path: 'uso-da-plataforma',
      name: 'AppsConfigsPlataformUse',
      component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/plataformUse/Index.vue'),
    },
    {
      path: 'fluxo',
      name: 'AppsConfigsFlow',
      component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/flow/Index.vue'),
    },
    {
      path: 'departamentos',
      name: 'AppsConfigsDepartments',
      component: () => import(/* webpackChunkName: "Configs" */ '@apps/configs/departments/Index.vue'),
      meta: {
        title: 'Departamentos',
        subject: 'Department',
        action: 'list',
        newTitle: 'Adicionar departamento',
        newRoute: 'AppsConfigsDepartmentNew',
      },
      children: [
        {
          path: 'criar',
          name: 'AppsConfigsDepartmentNew',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/departments/New.vue'),
          meta: {
            title: 'Criar Departmento',
            subject: 'Department',
            action: 'create',
            newTitle: 'Adicionar departamento',
          },
        },
        {
          path: ':departmentId/editar',
          name: 'AppsConfigsDepartmentEdit',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/departments/Edit.vue'),
          meta: { title: 'Edit Departmento', subject: 'Department', action: 'update' },
        },
        {
          path: ':departmentId',
          name: 'AppsConfigsDepartmentShow',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/departments/Show.vue'),
          meta: { title: 'Exibir Departmento', subject: 'Department', action: 'read' },
        },
      ],
    },
    {
      path: 'unidades',
      name: 'AppsConfigsUnits',
      component: () => import(/* webpackChunkName: "Configs" */ '@apps/configs/units/Index.vue'),
      meta: {
        title: 'Unidades',
        subject: 'Unit',
        action: 'list',
        newTitle: 'Adicionar unidade',
        newRoute: 'AppsConfigsUnityNew',
        newMultpleTitle: 'Adicionar múltiplas unidades',
        newMultpleRoute: 'AppsConfigsUnityNewMultiple',
      },
      children: [
        {
          path: 'criar',
          name: 'AppsConfigsUnityNew',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/units/New.vue'),
          meta: { title: 'Adicionar unidade', newTitle: 'Adicionar Unidade' },
        },
        {
          path: 'criar-multiplas',
          name: 'AppsConfigsUnityNewMultiple',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/units/NewMultiple.vue'),
          meta: { title: 'Adicionar múltiplas unidades' },
        },
        {
          path: ':unitId/editar',
          name: 'AppsConfigsUnityEdit',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/units/Edit.vue'),
          meta: { title: 'Editar unidade' },
        },
        {
          path: ':unitId',
          name: 'AppsConfigsUnityShow',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/units/Show.vue'),
          meta: { title: 'Exibir unidade' },
        },
        {
          path: ':unitId/perfil',
          name: 'AppsConfigsUnityProfile',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/units/Profile.vue'),
          meta: { title: 'Perfil da unidade' },
        },
        {
          path: ':unitId/documentos',
          name: 'AppsConfigsUnityDocs',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/units/Documents.vue'),
          meta: { title: 'Documentos' },
          children: [
            {
              path: 'criar',
              name: 'AppsConfigsUnityDocsNew',
              component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/units/NewDocument.vue'),
              meta: { title: 'Adicionar documento' },
            },
            {
              path: ':documentId/editar',
              name: 'AppsConfigsUnityDocsEdit',
              component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/units/EditDocument.vue'),
              meta: { title: 'Editar documento' },
            },
          ],
        },
      ],
    },
    {
      path: 'usuarios',
      name: 'AppsConfigsUsers',
      component: () => import(/* webpackChunkName: "Configs" */ '@apps/configs/users/Index.vue'),
      meta: {
        title: 'Usuários',
        subject: 'User',
        action: 'list',
        newTitle: 'Adicionar usuário',
        newRoute: 'AppsConfigsUserNew',
        newMultpleTitle: 'Adicionar múltiplos usuários',
        newMultpleRoute: 'AppsConfigsUserNewMultiple',
      },
      children: [
        {
          path: 'criar',
          name: 'AppsConfigsUserNew',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/users/New.vue'),
          meta: { title: 'Adicionar usuário', newTitle: 'Adicionar Usuário' },
        },
        {
          path: 'criar-multiplos',
          name: 'AppsConfigsUserNewMultiple',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/users/NewMultiple.vue'),
          meta: { title: 'Adicionar múltiplos usuários' },
        },
        {
          path: ':userId',
          name: 'AppsConfigsUserShow',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/users/Show.vue'),
          meta: { title: 'Exibir usuário' },
        },
        {
          path: ':userId/editar',
          name: 'AppsConfigsUserEdit',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/users/Edit.vue'),
          meta: { title: 'Editar usuário' },
        },
      ],
    },
    ROUTE_HOLIDAYS,
    ROUTE_FINANCIAL_INDEX,
    {
      path: 'integracao',
      name: 'AppsConfigsIntegration',
      component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/integration/Index.vue'),
    },
    {
      path: 'manuais',
      name: 'AppsConfigsManuals',
      component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/manuals/Index.vue'),
      children: [
        {
          path: ':manualId',
          name: 'AppsConfigsManualsShow',
          component: () => import(/* webpackChunkName: "Configs" */'@apps/configs/manuals/Show.vue'),
          meta: {
            title: 'Exibir manual',
            subject: 'Manual',
            action: 'read',
            closeRoute: 'Manuals',
          },
        },
      ],
    },
    ...ROUTE_MODELS,
  ],
}
