<template>
  <h2 class="subtitle-2 font-weight-bold">
    {{ title }} <span class="body-2 font-weight-thin">{{ value }}</span>
  </h2>
</template>

<script>
export default {
  name: 'UiTitleValue',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    value: {
      type: String,
      default: '',
      required: true,
    },
  },
}
</script>
