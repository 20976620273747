<template>
  <div>
    <!-- Mobile Global Toolbar Navigation -->
    <v-toolbar v-if="isMobile" class="toolbar" color="transparent" flat>
      <v-app-bar-nav-icon v-if="this.$vuetify.breakpoint.xsOnly">
        <portal-target name="menuIcon" />
      </v-app-bar-nav-icon>
      <v-toolbar-title  class="pl-0 font-weight-bold display-1">
        <v-badge
          v-if="count"
          :content="count"
          color="primary"
          class="v-badge-count"
          inline
        >
          {{ title }}
        </v-badge>
        <span v-else>
          {{ title }}
        </span>
      </v-toolbar-title>
      <v-spacer />
      <!--Search toggler button -->
      <v-btn
        v-show="!hideSearch"
        @click="isShowSearch = !isShowSearch"
        icon
        depressed
        placeholder="Pesquise aqui">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <!-- Slot for filters, datepickers and others -->
      <slot />
      <!-- Mobile Help Tooltip -->
      <help-tooltip v-if="$slots.hint" icon="mdi-help-circle" color-icon="primary">
        <slot name="hint" />
      </help-tooltip>
      <!-- Mobile User profile menu -->
      <v-menu

        v-model="showMenu"
        bottom
        min-width="200px"
        rounded
        offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="grey" size="34">
              <span class="white--text text-body-1">{{ userAbv }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="grey">
                <span class="white--text text-h5">{{ userAbv }}</span>
              </v-avatar>
              <h3 class="mt-3">{{ userName }}</h3>
              <p class="text-caption mb-0">
                {{ companyName }}
              </p>
              <p class="text-caption">
                {{ userEmail }}
              </p>
              <v-switch
                :input-value="$vuetify.theme.dark"
                @change="$vuetify.theme.dark = !$vuetify.theme.dark"
                append-icon="mdi-moon-waning-crescent"
                prepend-icon="mdi-white-balance-sunny"
                hide-details
                class="px-10 mb-5"/>
              <v-divider />
              <p
                v-if="isPrePaid"
                class="text-caption py-3 mb-0 primary--text grey"
                :class="this.$vuetify.theme.dark? 'darken-3' : 'lighten-2'"
              >
                <span class="font-weight-bold">Saldo:</span> {{ currencyFormat(getUserInfo.balance) }}
              </p>
              <v-divider />
              <v-dialog
                v-model="dialog"
                persistent
                max-width="300px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    large
                    depressed
                    block
                    tile
                    text
                    v-bind="attrs"
                    v-on="on">
                    Alterar Senha
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar
                    dark
                    short
                  >
                    <v-toolbar-title>Alterar Senha</v-toolbar-title>
                    <v-spacer />
                    <v-btn
                      icon
                      dark
                      @click="cancelChangePassword"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-form
                    ref="formPassword"
                    v-model="validPassword"
                    lazy-validation>
                    <v-card-text class="py-0">
                      <v-container>
                        <v-row>
                          <v-col cols="12" class="pb-0">
                            <v-text-field
                              v-model="password"
                              label="Nova Senha*"
                              name="password"
                              type="password"
                              filled
                              dense
                              :rules="passwordRules"
                              required />
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                              v-model="confirmPassword"
                              label="Confirmar Senha*"
                              name="confirmPassword"
                              type="password"
                              filled
                              dense
                              :rules="confirmPasswordRules"
                              required />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="secondary lighten-2"
                        depressed
                        text
                        @click="cancelChangePassword">
                        Cancelar
                      </v-btn>
                      <v-btn
                        class="font-weight-bold"
                        depressed
                        :dark="$vuetify.theme.dark"
                        :color="!$vuetify.theme.dark ? 'primary' : null"
                        :disabled="!validPassword"
                        @click="changePassword">
                        Alterar
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
              <v-divider />
              <v-dialog
                v-model="dialogBalance"
                persistent
                max-width="85%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isPrePaid"
                    large
                    depressed
                    block
                    tile
                    text
                    v-bind="attrs"
                    v-on="on">
                    Adicionar Saldo
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar
                    dark
                    short
                  >
                    <v-toolbar-title>Adicionar Saldo</v-toolbar-title>
                    <v-spacer />
                    <v-btn
                      icon
                      dark
                      @click="dialogBalance = false; showMenu = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text class="pt-5">
                    <v-form ref="formBalance" v-model="validBalance" lazy-validation>
                      <v-jsf v-model="modelBalance" :schema="schemaBalance" :options="formOptions">
                        <template v-slot:amount="slotProps">
                          <v-text-field
                            v-money="money"
                            :rules="currencyRules"
                            :value="slotProps.value"
                            :label="slotProps.label"
                            type="tel"
                            prefix="R$"
                            filled
                            v-on="slotProps.on" />
                        </template>
                      </v-jsf>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="secondary lighten-2"
                      depressed
                      text
                      @click="cancelAddBalance">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :dark="$vuetify.theme.dark"
                      :color="!$vuetify.theme.dark ? 'primary' : null"
                      depressed
                      class="font-weight-bold"
                      :disabled="modelBalance.amount == '0,00'"
                      @click="addBalance">
                      Continuar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-divider />
              <v-dialog
                v-model="dialogRescueBalance"
                persistent
                max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isPrePaid"
                    large
                    depressed
                    block
                    tile
                    text
                    v-bind="attrs"
                    v-on="on"
                    :disabled="parseFloat(getUserInfo.balance) == 0"
                    >
                    Resgatar Saldo
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar
                    dark
                    short
                  >
                    <v-toolbar-title>Resgate de Saldo</v-toolbar-title>
                    <v-spacer />
                    <v-btn
                      icon
                      dark
                      @click="dialogRescueBalance = false; showMenu = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text class="pt-5">
                    <v-form ref="formRescueBalance" v-model="validRescueBalance" lazy-validation>
                      <v-jsf v-model="modelRescueBalance" :schema="schemaRescueBalance" :options="formOptions">
                        <template v-slot:amountRescue="slotProps">
                          <v-text-field
                            v-model="amountRescue"
                            v-money="money"
                            :rules="currencyRules"
                            :value="slotProps.value"
                            :label="slotProps.label"
                            prefix="R$"
                            filled
                            v-on="slotProps.on" />
                        </template>
                        <template v-slot:pixKey="slotProps">
                          <v-text-field
                            :rules="balanceRules"
                            :value="slotProps.value"
                            :label="slotProps.label"
                            required
                            filled
                            v-on="slotProps.on" />
                        </template>
                      </v-jsf>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary lighten-2"
                      depressed
                      text
                      @click="cancelRescueBalance">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :dark="$vuetify.theme.dark"
                      :color="!$vuetify.theme.dark ? 'primary' : null"
                      elevation="0"
                      class="font-weight-bold"
                      :disabled="modelRescueBalance.amountRescue == '0,00' && balanceRules == true"
                      @click="addRescueBalance">
                      Resgatar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogBalancePayment"
                max-width="85%"
                persistent
              >
                <v-card>
                  <v-toolbar
                    dark
                    short
                  >
                    <v-toolbar-title>Pagamento via {{ modelBalance.paymentMethod }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn
                      icon
                      dark
                      @click="dialogBalancePayment = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text class="py-5">
                    <p v-if="modelBalance.paymentMethod === 'PIX'" class="text-center pb-0">
                      Abra o aplicativo do seu banco no celular e aponte a câmera para a imagem abaixo:
                    </p>
                    <v-progress-linear
                      v-show="paymentGatewayLoading"
                      class="mb-4"
                      indeterminate
                    ></v-progress-linear>
                    <v-img
                      v-if="!paymentGatewayLoading && modelBalance.paymentMethod === 'PIX'"
                      :lazy-src="`data:image/gif;base64,${paymentGateway[0].qrCode?.base64Image}`"
                      :src="`data:image/gif;base64,${paymentGateway[0].qrCode?.base64Image}`"
                      max-width="280"
                      max-height="280"
                      contain
                      class="mx-auto"
                    ></v-img>
                    <v-btn
                      v-if="!paymentGatewayLoading && modelBalance.paymentMethod === 'Boleto'"
                      color="secondary"
                      @click="dialogBalancePayment = false"
                      block
                    >
                      <v-icon left>mdi-download</v-icon>
                      Baixar boleto
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-divider />
              <v-btn
                large
                depressed
                block
                tile
                text
                @click="logout"
                >
                <v-icon left>mdi-exit-to-app</v-icon>
                Sair da conta
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-toolbar>
    <!-- Mobile Search Global Toolbar -->
    <v-toolbar v-if="isShowSearch" class="toolbar" color="transparent" flat>
      <v-text-field
        v-model="search"
        label="Pesquise aqui"
        prepend-inner-icon="mdi-magnify"
        class="search-input search-input__mobile"
        solo
        dense
        flat
        clearable
        single-line
        full-width
        hide-details
        />
        <speech-button v-show="!hideSearch" :text.sync="search"/>
    </v-toolbar>
    <!-- Global Toolbar Navigation -->
    <v-toolbar v-if="!isMobile" class="toolbar filters" color="transparent" flat>
      <v-toolbar-title class="font-weight-bold display-1">
        <v-badge
          v-if="count"
          :content="count"
          class="v-badge-count"
          inline
        >
          {{ title }}
        </v-badge>
        <span v-else>
          {{ title }}
        </span>
      </v-toolbar-title>
      <v-spacer />
      <!-- Filter slot Desktop-->
      <slot name="filters" />
      <!-- Desktop Search Global Toolbar-->
      <v-text-field
        v-show="!hideSearch"
        v-model="search"
        class="search-input"
        label="Pesquise aqui"
        prepend-inner-icon="mdi-magnify"
        solo
        dense
        flat
        clearable
        hide-details
        />
      <speech-button v-show="!hideSearch" :text.sync="search"/>

      <!-- Filter slot Desktop -->
      <slot name="filtersAfter" />
      <!-- Slot for filters, datepickers and others (default) -->
      <slot />
      <!-- Desktop Help Tooltip -->
      <help-tooltip v-if="$slots.hint" icon="mdi-help-circle" color-icon="primary">
        <slot name="hint" />
      </help-tooltip>
    </v-toolbar>

    <!-- Filter slot Mobile -->
    <v-toolbar v-if="isMobile && ($slots.filters || $slots.filtersAfter)" class="toolbar filters" color="transparent" flat>
      <slot name="filters" />
      <slot name="filtersAfter" />
    </v-toolbar>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import useAppStore from '@core/stores/app'
import SpeechButton from '@ui/SpeechButton.vue'
import { initialAbility } from '@core/plugins/acl/config'
import useJwt from '@core/auth/jwt/useJwt'
import masks from '@core/mixins/masks'
import validate from '@core/mixins/validate'

export default {
  name: 'GlobalToolbar',
  mixins: [masks, validate],
  components: { SpeechButton },
  props: {
    // Prop for main title
    title: {
      type: String,
    },
    // Prop for count
    count: {
      type: String,
    },
    // Prop to show/hide search text input
    hideSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      offset: false,
      isShowSearch: false,
      search: '',
      showMenu: false,
      dialog: false,
      dialogBalance: false,
      dialogRescueBalance: false,
      dialogBalancePayment: false,
      validPassword: true,
      companyName: 'Mais Tecnologias',
      getUserInfo: {
        name: 'Alex',
        balance: '0',
      },
      amount: '',
      password: '',
      confirmPassword: '',
      requiredRules: [
        value => !!value || 'Digite o valor',
      ],
      passwordRules: [
        value => !!value || 'Digite a senha.',
        value => (value && value.length >= 8) || 'Mínimo 8 caracteres',
      ],
      confirmPasswordRules: [
        value => !!value || 'Confirme a senha',
        value => value === this.password || 'Senha não confere.',
      ],
      // Add balance form settings
      validBalance: false,
      modelBalance: {},
      schemaBalance: {
        type: 'object',
        required: [
          'amount',
          'paymentMethod',
        ],
        properties: {
          amount: {
            type: 'number',
            title: 'Valor*',
          },
          paymentMethod: {
            type: 'string',
            title: 'Forma de pagamento:',
            enum: [
              'Boleto',
              'PIX',
            ],
            'x-display': 'radio',
          },
        },
      },
      // Rescue Balance form settings
      validRescueBalance: false,
      modelRescueBalance: {},
      amountRescue: 0,
      amountRescueValid: false,
      schemaRescueBalance: {
        type: 'object',
        required: [
          'amountRescue',
          'pixKeyType',
          'pixKey',
        ],
        properties: {
          amountRescue: {
            type: 'string',
            title: 'Valor*',
          },
          pixKeyType: {
            type: 'string',
            title: 'Tipo da chave PIX',
            'x-if': 'parent.value.amountRescue',
            'x-options': {
              evalMethod: 'evalExpr',
            },
            oneOf: [
              {
                const: 'emailRules',
                title: 'E-mail',
              },
              {
                const: 'cpfRules',
                title: 'CPF',
              },
              {
                const: 'cnpjRules',
                title: 'CNPJ',
              },
              {
                const: 'phoneRules',
                title: 'Número de telefone celular',
              },
              {
                const: 'pixRandomKeyRules',
                title: 'Chave aleatória',
              },
            ],
          },
          pixKey: {
            type: 'string',
            title: 'Chave PIX',
            'x-if': 'parent.value.pixKeyType',
            'x-options': {
              evalMethod: 'evalExpr',
            },
          },
        },
      },
      formOptions: {
        fieldProps: {
          filled: true,
          dense: true,
        },
        selectProps: {
          clearable: true,
        },
        messages: {
          required: 'Esta informação é necessária',
        },
        sectionsClass: 'formSection',
        childrenClass: 'pa-0',
        hideReadOnly: false,
        removeAdditionalProperties: true,
        icons: {
          calendar: '',
        },
        debug: true,
        disableAll: false,
        autoFoldObjects: true,
      },
    }
  },
  setup() {
    const appStore = useAppStore()

    return {
      appStore,
    }
  },
  computed: {
    ...mapState(useAppStore, ['userName', 'userAbv', 'userEmail', 'paymentGateway', 'paymentGatewayLoading', 'isPrePaid']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    balanceRules() {
      return this.modelRescueBalance?.pixKeyType ? this[this.modelRescueBalance?.pixKeyType] : true
    },
  },
  watch: {
    search(value) {
      this.$emit('search', value)
    },
    amountRescue(val) {
      const rescueVal = parseInt(val.toString().replaceAll(/\D+/g, ''), 10)
      const balanceVal = parseInt(this.getUserInfo.balance.toString().replaceAll(/\D+/g, ''), 10)

      this.amountRescueValid = rescueVal > 0 && rescueVal <= balanceVal
    },
  },
  methods: {
    currencyFormat(value) {
      const BRLReal = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
      return BRLReal.format(value)
    },
    async addBalance() {
      if (this.$refs.formBalance.validate()) {
        await this.appStore.fetchPaymentGateway(this.modelBalance)

        this.dialogBalancePayment = true
        this.dialogBalance = false
        this.showMenu = false
      }
    },
    cancelAddBalance() {
      this.validBalance = true
      this.dialogBalance = false
      this.showMenu = false
      this.amount = ''
    },
    async addRescueBalance() {
      if (this.$refs.formRescueBalance.validate()) {
        if (this.amountRescueValid) {
          await this.appStore.fetchPaymentGateway(this.modelBalance)
          this.validRescueBalance = true
          this.dialogRescueBalance = false
          this.showMenu = false
          // eslint-disable-next-line object-curly-newline, max-len
          const message = { show: true, time: 10000, color: 'success', text: '<b>Sua solicitação foi recebida. O Pix do resgate será realizado em breve.</b>' }
          this.$root.$emit('snackbar', message)
        } else {
          // eslint-disable-next-line object-curly-newline
          const message = { show: true, time: 10000, color: 'error', text: '<b>Saldo insuficiente para resgate</b>' }
          this.$root.$emit('snackbar', message)
        }
      }
    },
    cancelRescueBalance() {
      this.dialogRescueBalance = false
      this.showMenu = false
    },
    async copyCode() {
      await navigator.clipboard.writeText(this.paymentGateway[0].qrCode.emv)
      // eslint-disable-next-line object-curly-newline
      const message = { show: true, timeout: 2000, color: 'info', text: 'Copiado para a área de transfêrencia' }
      this.$root.$emit('snackbar', message)
      this.dialogBalancePayment = false
    },
    async changePassword() {
      if (this.$refs.formPassword.validate()) {
        const { password } = this

        await this.appStore.userChangePassword({ password })

        // const { error } = await this.supabase.auth.update({ password })
        // if (error) return this.$root.$emit('snackbar', { show: true, text: 'Falha ao atualizar senha', color: 'error' })

        // this.$root.$emit('snackbar', { show: true, text: 'Senha alterada com sucesso.', color: 'success' })
        this.validPassword = true
        this.dialog = false
        this.showMenu = false
        this.password = ''
        this.confirmPassword = ''
      }

      return ''
    },
    cancelChangePassword() {
      this.validPassword = true
      this.dialog = false
      this.showMenu = false
      this.password = ''
      this.confirmPassword = ''
    },
    async logout() {
      const { error } = await this.supabase.auth.signOut()

      if (!error) {
        // Add action in Audit
        useJwt.logout()

        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Reset ability
        this.$ability.update(initialAbility)

        // Redirect to login page
        this.$router.push({ name: 'Login' })
      }
    },
  },
}
</script>
<style lang="sass">
.v-badge-count
  .v-badge__wrapper
    top: -2px
    > span
      color: var(--badge)
.filters
  height: auto
  & > .v-toolbar__content
    flex-direction: column
    .filterSelect
      margin: 0px auto 10px
      width: 100%
      .v-input__control > .v-input__slot
        padding: 0 14px

@media screen and (min-width: $max-mobile)
  .v-badge-count
    .v-badge__wrapper
      top: -4px
  .filters
    height: 56px !important
    & > .v-toolbar__content
      flex-direction: row
      .filterSelect
        margin: initial
        max-width: 250px
        .v-input__control > .v-input__slot
          padding: 0 24px
</style>
