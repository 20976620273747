import Vue from 'vue'
import { createClient } from '@supabase/supabase-js'

// eslint-disable-next-line max-len
const supabase = createClient('https://gw.maistecnologias.com.br', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndobGh6ZGJ5c25sZ3VwZm5ydGV3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDc4NDE0NzMsImV4cCI6MTk2MzQxNzQ3M30.csb5uoFGH8kvkHG6CPEEpW-GynkC0D1dMuN4FvQxQQA', {
  schema: process.env.VUE_APP_SUPABASE_SCHEMA || 'public',
})

Vue.prototype.supabase = supabase

export default supabase
