import { canNavigate } from '@core/plugins/acl/routeProtection'
import { isUserLoggedIn } from '@core/auth/utils'
import useAppStore from '@core/stores/app'

export default async (to, _, next) => {
  const isLoggedIn = await isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'Login' })

    // If logged in => not authorized
    return next({ name: 'NotAuthorized' })
  }

  if (isLoggedIn) {
    const appStore = useAppStore()

    await appStore.statsCount()
  }

  // // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()

  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }

  return next()
}
