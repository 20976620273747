export default {
  path: 'feriados',
  name: 'Holidays',
  component: () => import(/* webpackChunkName: "Holidays" */ './Index.vue'),
  meta: {
    title: 'Feriados',
    subject: 'Holiday',
    action: 'list',
    newTitle: 'Adicionar Feriado',
    newRoute: 'HolidaysNew',
  },
  children: [
    {
      path: 'criar',
      name: 'HolidaysNew',
      component: () => import(/* webpackChunkName: "Holidays" */'./New.vue'),
      meta: { title: 'Adicionar feriado', newTitle: 'Adicionar Unidade' },
    },
    {
      path: ':unitId/editar',
      name: 'HolidaysEdit',
      component: () => import(/* webpackChunkName: "Holidays" */'./Edit.vue'),
      meta: { title: 'Editar feriado' },
    },
    {
      path: ':unitId',
      name: 'HolidaysShow',
      component: () => import(/* webpackChunkName: "Holidays" */'./Show.vue'),
      meta: { title: 'Exibir feriado' },
    },
  ],
}
