<template>
  <v-overlay
    absolute
    :opacity="opacity"
    color="overlay"
    class="d-flex">
    <v-card min-width="360" max-width="420" flat color="transparent">
      <v-card-text class="body-2">
        <span class="text-h6 font-weight-thin" style="color: var(--overlay-text)">
          {{ msg }}
        </span>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-btn
          block
          color="font-weight-bold text-uppercase"
          depressed
          :to="{ name: $route.meta.newRoute }"
          v-show="$route.meta.newRoute"
          >{{ $route.meta.newTitle }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: 'OverlayEmpty',
  data: () => ({}),
  props: {
    opacity: {
      type: String,
      default: '0.6',
    },
    msg: {
      type: String,
      default: 'Ainda não há registros nesta página. Clique no botão abaixo para adicionar.',
    },
  },
}
</script>
