/* eslint-disable object-curly-newline */
export default {
  path: '/documentos',
  component: () => import(/* webpackChunkName: "Documents" */ './Layout.vue'),
  children: [
    {
      path: '/',
      name: 'Document',
      component: () => import(/* webpackChunkName: "Documents" */'./Index.vue'),
      meta: { title: 'Documentos', subject: 'Document', action: 'list', newTitle: 'Adicionar Documento', newRoute: 'DocumentNew' },
    },
    {
      path: 'criar',
      name: 'DocumentNew',
      component: () => import(/* webpackChunkName: "Documents" */'./New.vue'),
      meta: { title: 'Criar documento', subject: 'Document', action: 'create' },
    },
    {
      path: ':documentId/editar',
      name: 'DocumentEdit',
      component: () => import(/* webpackChunkName: "Documents" */'./Edit.vue'),
      meta: { title: 'Editar documento', subject: 'Document', action: 'update' },
    },
    {
      path: ':documentId',
      name: 'DocumentShow',
      component: () => import(/* webpackChunkName: "Documents" */'./Show.vue'),
      meta: { title: 'Exibir documento', subject: 'Document', action: 'read' },
    },
  ],
}
