// import { RequireAutorization } from './routerAuth'

export default {
  name: 'Financial',
  path: '/financeiro',
  component: () => import(/* webpackChunkName: "Financial" */ '@apps/financial/Index.vue'),
  meta: { title: 'Financeiro' },
  children: [
    {
      path: 'campanhas',
      name: 'FinancialCampaigns',
      component: () => import(/* webpackChunkName: "Financial" */'@apps/financial/IndexCampaigns.vue'),
      meta: { title: 'Financeiro Campanhas', subject: 'Financial', action: 'campaigns' },
      children: [
        {
          path: ':id',
          name: 'FinancialShowCampaign',
          component: () => import(/* webpackChunkName: "Financial" */'@apps/financial/Show.vue'),
          meta: { title: 'Detalhes da campanha', subject: 'Financial', action: 'read-campaign' },
        },
      ],
    },
    {
      path: 'perfis',
      name: 'FinancialProfiles',
      component: () => import(/* webpackChunkName: "Financial" */'@apps/financial/IndexProfiles.vue'),
      meta: { title: 'Financeiro Perfis', subject: 'Financial', action: 'profiles' },
      children: [
        {
          path: ':id',
          name: 'FinancialShowProfile',
          component: () => import(/* webpackChunkName: "Financial" */'@apps/financial/Show.vue'),
          meta: { title: 'Detalhes do perfil', subject: 'Financial', action: 'read-profile' },
        },
      ],
    },
  ],
  // beforeEnter: RequireAutorization
}
