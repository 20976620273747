export default [
  {
    name: 'Reports',
    path: '/relatorios',
    component: () => import(/* webpackChunkName: "Reports" */ './Index.vue'),
    meta: { title: 'Relatórios', subject: 'Report', action: 'list' },
  },
  {
    name: 'ReportDebtStates',
    path: '/relatorios/situacao-titulos',
    component: () => import(/* webpackChunkName: "Reports" */ './ReportDebtStates.vue'),
    meta: { title: 'Relatórios', subject: 'Report', action: 'list' },
  },
  {
    name: 'ReportCompanyExists',
    path: '/relatorios/empresas-existentes-no-municipio',
    component: () => import(/* webpackChunkName: "Reports" */ './ReportCompanyExists.vue'),
    meta: { title: 'Relatórios', subject: 'Report', action: 'list' },
  },
  {
    name: 'ReportCompanyClosed',
    path: '/relatorios/empresas-existentes-baixadas-receita',
    component: () => import(/* webpackChunkName: "Reports" */ './ReportCompanyClosed.vue'),
    meta: { title: 'Relatórios', subject: 'Report', action: 'list' },
  },
  {
    name: 'ReportCompanyDivergent',
    path: '/relatorios/empresas-divergentes',
    component: () => import(/* webpackChunkName: "Reports" */ './ReportCompanyDivergent.vue'),
    meta: { title: 'Relatórios', subject: 'Report', action: 'list' },
  },
]
