<template>
  <v-tooltip bottom :min-width="minWitdh" :max-width="maxWitdh" :color="color">
    <template v-slot:activator="{ on, attrs }">
      <v-icon class="mx-2" :color="colorIcon" v-bind="attrs" v-on="on">{{ icon }}</v-icon>
    </template>
    <span>
      <slot />
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'HelpTooltip',
  data: () => ({}),
  props: {
    minWitdh: {
      type: String,
      default: '300',
    },
    maxWitdh: {
      type: String,
      default: '350',
    },
    color: {
      type: String,
      default: '#333',
    },
    icon: {
      type: String,
      default: 'mdi-help-circle',
    },
    colorIcon: {
      type: String,
      default: 'primary',
    },
  },
}
</script>

<style lang="sass" scoped>
  .v-tooltip__content.menuable__content__active
    opacity: 0.8
</style>
