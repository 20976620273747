export default {
  path: '/debitos',
  component: () => import(/* webpackChunkName: "Contacts" */ '@core/layout/LayoutClean.vue'),
  children: [
    {
      path: '/',
      name: 'Debts',
      component: () => import(/* webpackChunkName: "Debt" */ './Index.vue'),
      meta: { title: 'Débitos', subject: 'Debt', action: 'list' },
      children: [
        {
          path: 'criar-multiplos',
          name: 'DebtsNewMultiple',
          component: () => import(/* webpackChunkName: "Debt" */'./NewMultiple.vue'),
          meta: { title: 'Criar múltiplos títulos', subject: 'Debt', action: 'multi-create' },
        },
      ],
    },
    {
      path: 'filtros',
      name: 'DebtsFilters',
      component: () => import(/* webpackChunkName: "DebtsFilters" */'./FiltersIndex.vue'),
      meta: {
        title: 'Filtros de',
        show: true,
        subject: 'Debt',
        action: 'group-list',
        newTitle: 'Adicionar Filtro',
        newRoute: 'DebtsFiltersNew',
      },
    },
    {
      path: 'filtros/:debtsFilterId',
      name: 'DebtsFilterShow',
      component: () => import(/* webpackChunkName: "DebtsFilters" */'./FilterShow.vue'),
      meta: {
        title: 'Visualizar Filtro',
        routeShow: 'ContactsShow',
        show: false,
        subject: 'Debt',
        action: 'group-read',
      },
    },
  ],
}
