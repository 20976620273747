<template>
  <v-btn
    :class="{'animated infinite pulse': toggle}"
    :color="!toggle ? 'grey' : (speaking ? 'red' : 'red darken-3')"
    dark
    icon
    @click.stop="toggle ? endSpeechRecognition() : startSpeechRecognition()">
    <v-icon v-if="toggle">mdi-microphone-off</v-icon>
    <v-icon v-else>mdi-microphone</v-icon>
  </v-btn>
</template>

<script>
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
const recognition = SpeechRecognition ? new SpeechRecognition() : false

export default {
  name: 'SpeechButton',
  props: {
    lang: {
      type: String,
      default: 'pt-BR',
    },
    text: {
      type: [String, null],
      required: false,
    },
  },
  data() {
    return {
      error: false,
      speaking: false,
      toggle: false,
      runtimeTranscription: '',
      sentences: [],
    }
  },
  methods: {
    checkCompatibility() {
      if (!recognition) {
        this.error = 'O reconhecimento de fala não está disponível neste navegador. Por favor use o Chrome ou Firefox'
        this.$root.$emit('snackbar', { show: true, color: 'success', text: this.error })
        console.log(this.error)
      }
    },
    endSpeechRecognition() {
      recognition.stop()
      this.toggle = false
      this.$emit('speechend', {
        sentences: this.sentences,
        text: this.sentences.join('. '),
      })
    },
    startSpeechRecognition() {
      if (!recognition) {
        this.error = 'O reconhecimento de fala não está disponível neste navegador. Por favor use o Chrome ou Firefox'
        this.$root.$emit('snackbar', { show: true, color: 'success', text: this.error })
        return false
      }
      this.toggle = true
      recognition.lang = this.lang
      recognition.interimResults = true

      recognition.addEventListener('speechstart', () => {
        this.speaking = true
      })

      recognition.addEventListener('speechend', () => {
        this.speaking = false
      })

      recognition.addEventListener('result', event => {
        const text = Array.from(event.results).map(result => result[0]).map(result => result.transcript).join('')
        this.runtimeTranscription = text
      })

      recognition.addEventListener('end', () => {
        if (this.runtimeTranscription !== '') {
          this.sentences.push(this.capitalizeFirstLetter(this.runtimeTranscription))
          this.$emit('update:text', `${this.text}${this.sentences.slice(-1)[0]}. `)
        }
        this.runtimeTranscription = ''
        recognition.stop()
        if (this.toggle) {
          // keep it going.
          recognition.start()
        }
      })
      recognition.start()

      return ''
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
  mounted() {
    this.checkCompatibility()
  },
}
</script>
