import Vue from 'vue'
import numeral from 'numeral'

numeral.register('locale', 'pt-BR', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: 'R$ ',
  },
})

numeral.locale('pt-BR')

Vue.prototype.numeral = numeral
