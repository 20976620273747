export default {
  data: () => ({
    windowSize: {
      x: 0,
      y: 0,
    },
  }),
  mounted() {
    this.onResize()
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
  computed: {
    setHeightScroll() {
      const heightCalc = this.windowSize.y - 125

      return `height: ${heightCalc}px; overflow-y: auto; margin-bottom: 20px`
    },
  },
}
