// import { RequireAutorization } from './routerAuth'

export default {
  path: '/casos',
  component: () => import(/* webpackChunkName: "Cases" */ '@core/layout/LayoutClean.vue'),
  meta: {
    title: 'Casos',
    subject: 'Case',
    action: 'list',
    newTitle: 'Adicionar Caso',
    newRoute: 'CasesNew',
  },
  // beforeEnter: RequireAutorization
  children: [
    {
      path: 'criar',
      name: 'CasesNew',
      component: () => import(/* webpackChunkName: "Cases" */ './New.vue'),
      meta: {
        title: 'Adicionar caso',
        subject: 'Case',
        action: 'create',
        newTitle: 'Adicionar Caso',
      },
    },
    {
      path: ':caseId/editar',
      name: 'CasesEdit',
      component: () => import(/* webpackChunkName: "Cases" */ './Edit.vue'),
      meta: { title: 'Editar caso', subject: 'Case', action: 'update' },
    },
    {
      path: '',
      component: () => import(/* webpackChunkName: "Cases" */'./Index.vue'),
      children: [
        {
          path: 'painel',
          name: 'CasesPanel',
          component: () => import(/* webpackChunkName: "Cases" */'./Panel.vue'),
          meta: {
            title: 'Painel de Casos', show: true, subject: 'CasesPanel', action: 'list',
          },
        },
        {
          path: '/',
          name: 'Cases',
          component: () => import(/* webpackChunkName: "Cases" */'./List.vue'),
          meta: {
            title: 'Casos',
            subject: 'Cases',
            action: 'list',
            newTitle: 'Adicionar Caso',
            newRoute: 'CasesNew',
          },
          children: [
            {
              path: 'criar-multiplos',
              name: 'CasesNewMultiple',
              component: () => import(/* webpackChunkName: "Cases" */ './NewMultiple.vue'),
              meta: { title: 'Detalhes do Caso', subject: 'Case', action: 'multi-create' },
            },
          ],
        },
      ],
    },
    {
      path: ':caseId',
      component: () => import(/* webpackChunkName: "Cases" */ './LayoutShow.vue'),
      children: [
        {
          path: '/',
          name: 'CasesShow',
          component: () => import(/* webpackChunkName: "Cases" */ './Show.vue'),
          meta: { title: 'Detalhes do Caso', subject: 'Case', action: 'read' },
          children: [
            {
              path: 'transferir',
              name: 'CasesTransfer',
              component: () => import(/* webpackChunkName: "Cases" */ './Transfer.vue'),
              meta: { title: 'Transferir', subject: 'Case', action: 'transfer' },
            },
          ],
        },
        {
          path: 'partes',
          name: 'CasesShowParts',
          component: () => import(/* webpackChunkName: "Cases" */ './parts/ShowParts.vue'),
          meta: { title: 'Detalhes do Caso', subject: 'Case', action: 'read-part' },
          children: [
            {
              path: 'adicionar',
              name: 'CasesNewPart',
              component: () => import(/* webpackChunkName: "Cases" */ './parts/NewPart.vue'),
              meta: {
                title: 'Detalhes do Caso',
                subject: 'Case',
                action: 'create-part',
                params: { isNewCase: false },
              },
            },
            {
              path: ':partId',
              name: 'CasesEditPart',
              component: import(/* webpackChunkName: "Cases" */ './parts/EditPart.vue'),
              meta: { title: 'Editar parte', subject: 'Case', action: 'update-part' },
            },
          ],
        },
        {
          path: 'agendamentos',
          name: 'CasesShowSchedules',
          component: () => import(/* webpackChunkName: "Cases" */ './SchedulesList.vue'),
          meta: { title: 'Detalhes do Caso', subject: 'Case', action: 'read' },
          children: [
            {
              path: 'adicionar',
              name: 'CasesNewAudience',
              component: () => import(/* webpackChunkName: "Cases" */ './NewAudience.vue'),
              meta: { title: 'Detalhes do Caso', subject: 'Case', action: 'create-audience' },
            },
          ],
        },
        {
          path: 'arquivos',
          name: 'CasesFiles',
          component: () => import(/* webpackChunkName: "Cases" */ './Files.vue'),
          meta: { title: 'Detalhes do Caso', subject: 'Case', action: 'files' },
          children: [
            {
              path: 'adicionar',
              name: 'CasesNewDocument',
              component: () => import(/* webpackChunkName: "Cases" */ './FilesNew.vue'),
              meta: { title: 'Detalhes do Caso', subject: 'Case', action: 'files' },
            },
          ],
        },
        {
          path: 'acionamentos',
          name: 'CasesShowDrives',
          component: () => import(/* webpackChunkName: "Cases" */ './ShowDrives.vue'),
          meta: { title: 'Detalhes do Caso', subject: 'Case', action: 'show-drives' },
        },
        {
          path: 'timeline',
          name: 'CasesShowTimeline',
          component: () => import(/* webpackChunkName: "Cases" */ './Timeline.vue'),
          meta: { title: 'Detalhes do Caso', subject: 'Case', action: 'show-drives' },
        },
      ],
    },
  ],
}
