export default {
  path: '/pagamentos',
  name: 'Payments',
  component: () => import(/* webpackChunkName: "payments" */ '@core/layout/LayoutClean.vue'),
  children: [
    {
      name: 'PaymentsTicket',
      path: 'boleto/:id',
      component: () => import(/* webpackChunkName: "payments" */'@apps/payment/Boleto.vue'),
      meta: { title: 'Mais - Pagamento de dívida', subject: 'Auth' },
    },
    // {
    //   name: 'PaymentPix',
    //   path: '/pix/:id',
    //   component: () => import(/* webpackChunkName: "payment" */'@apps/payment/Pix.vue'),
    //   meta: { title: 'Mais - Pagamento de dívida', subject: 'Auth' },
    // },
  ],
}
