import ROUTE_SIGNATURES from '@apps/signatures/router'
import ROUTE_PROPOSALS from '@apps/proposals/router'

export default [
  {
    name: 'Login',
    path: '/login',
    component: () => import(/* webpackChunkName: "notAuthenticated" */'@core/apps/auth/Login.vue'),
    meta: { title: 'Mais - Login', subject: 'Auth', redirectIfLoggedIn: true },
  },
  {
    name: 'ForgotPassword',
    path: '/esqueci-minha-senha',
    component: () => import(/* webpackChunkName: "notAuthenticated" */'@core/apps/auth/ForgotPassword.vue'),
    meta: { title: 'Mais - Esqueci minha senha', subject: 'Auth' },
  },
  {
    name: 'ResetPassword',
    path: '/nova-senha',
    component: () => import(/* webpackChunkName: "notAuthenticated" */'@core/apps/auth/ResetPassword.vue'),
    meta: { title: 'Mais - Nova senha', subject: 'Auth' },
  },
  {
    name: 'Payment',
    path: '/pagamento/:invoiceId',
    component: () => import(/* webpackChunkName: "payment" */'@apps/payment/Index.vue'),
    meta: { title: 'Mais - Pagamento de dívida', subject: 'Auth' },
  },
  ROUTE_PROPOSALS,
  ROUTE_SIGNATURES,
]
