export const link = {
  home: 'mdi-home',
  info: 'mdi-info',
  cases: 'mdi-folder',
  companies: 'mdi-domain',
  protests: 'mdi-gavel',
  protests_book: 'mdi-book-open-variant',
  negativation: 'mdi-numeric-negativation-1',
  negativation_group: 'mdi-book-open-variant',
  events: 'mdi-file-document',
  contacts: 'mdi-history',
  multiple_cases: 'mdi-folder-upload',
  users: 'mdi-supervisor_account',
  financial_indices: 'mdi-currency-usd',
  schedule: 'mdi-calendar',
  channels: 'mdi-forum',
  settings: 'mdi-cog',
  messages: 'mdi-email',
  timeline: 'mdi-clock-fast',
  chat: 'mdi-forum-outline',
  tickets_case: 'mdi-buffer',
  proposals: 'mdi-handshake',
  invited: 'mdi-account-card-details',
  attach: 'mdi-attachment',
  notes: 'mdi-notebook',
  video: 'mdi-videocam',
  financial: 'mdi-account_balance',
  location: 'mdi-location_on',
  logo: 'mdi-camera',
  invoice: 'mdi-attach_money',
  flows: 'mdi-format_line_spacing',
  payment: 'mdi-payment',
  reports: 'mdi-file-chart',
  agenda: 'mdi-calendar',
  billings: 'mdi-text-box',
}

const term = 'mdi-file-document'

const state = {
  show: 'keyboard_arrow_down',
  hide: 'keyboard_arrow_up',
  show_pass: 'mdi-eye',
  hide_pass: 'mdi-eye-off',
  actived: 'check_circle',
  disabled: 'cancel',
  checked: 'radio_button_checked',
  unchecked: 'radio_button_unchecked',
  warning: 'alert-circle',
  error: 'error',
  success: '',
  no_result: 'find_in_page',
  bounce: 'do_not_disturb_alt',
}

const layout = {
  label: 'label_outline',
  label_onboarding: 'turned_in',
  notification: 'notifications',
  task: 'filter_frames',
  phone: 'phone_in_talk',
  video: link.video,
  search: 'search',
  place: 'place',
  user: 'person',
  hide_menu_contextual: 'arrow_forward',
  term,
  empty_chart: 'mdi-chart-donut',
  attach_file: 'attach_file',
  upload: 'file_upload',
  more_x: 'more_horiz',
  more_y: 'more_vert',
  calendar: 'event',
  percent: 'mdi-percent',
  files: 'mdi-file-multiple',
  tip: 'wb_incandescent',
  alert: 'sim_card_alert',
  user_avatar: 'mdi-camera',
  alarm: 'access_alarms',
  lock: 'lock',
  help: 'mdi-help',
  help_circle: 'mdi-help-circle',
  menu_down: 'mdi-menu-down',
  credit_card: 'mdi-credit-card',
  ticket: 'mdi-barcode',
  generator_file: 'mdi-download',
  password: 'mdi-fingerprint',
}

const channel = {
  email: 'mdi-at',
  sms: 'textsms',
  letter: link.messages,
}

const action = {
  add: 'add',
  on: 'mdi-checkbox-blank-outline',
  off: 'mdi-checkbox-marked-outline',
  remove: 'remove',
  edit: 'edit',
  cancel: 'cancel',
  delete: 'delete',
  save: 'save',
  logout: 'exit_to_app',
  eye: 'mdi-eye',
  close: 'mdi-close',
  reply: 'reply',
  replay: 'replay',
  charge: 'charge',
  send: 'send',
  previous: 'subdirectory_arrow_left',
  next: 'subdirectory_arrow_right',
  expand: 'mdi-arrow-collapse-right',
  decrease: 'mdi-arrow-collapse-left',
  slide_add: 'control_point',
  slide_remove: 'remove_circle_outline',
  video_on: link.video,
  video_off: 'videocam_off',
  audio_on: 'keyboard_voice',
  audio_off: 'mic_off',
  profile_image_edit: 'account_circle',
  back: 'mdi-arrow-left',
}

const timeline = {
  case_created: link.cases,
  case_expired: link.cases,
  invite_created: channel.email,
  invite_delivered: channel.email,
  invite_processed: channel.email,
  invite_open: channel.email,
  invite_bounce: state.bounce,
  invite_click: 'mdi-check-circle',
  invite_dropped: 'mdi-cancel',
  proposal_created: link.proposals,
  proposal_visualized: link.proposals,
  proposal_accepted: 'mdi-thumb-up',
  document_created: link.channels,
  proposal_refused: 'mdi-thumb-down',
  case_negotiation: link.chat,
  note_created: link.attach,
  schedule_created: link.schedule,
  schedule_updated: link.schedule,
  schedule_completed: link.schedule,
  schedule_canceled: link.schedule,
  term_accepted: term,
  term_refused: term,
  term_fruitless: term,
  term_dropped: 'mdi-alert-decagram',
  others: link.info,
}

export const icons = {
  link: {
    ...link,
  },
  layout: {
    ...layout,
  },
  channel: {
    ...channel,
  },
  action: {
    ...action,
  },
  state: {
    ...state,
  },
  timeline: {
    ...timeline,
  },
}
