/* eslint-disable no-plusplus */
/* eslint-disable max-len */
const required = v => !!v || 'Campo obrigatório'
const boolean = v => [true, false].includes(v) || 'Campo obrigatório'

const maxLength = length => v => (v && v.length <= length) || `Excedeu ${length} caracteres`

const minLength = length => v => (v.length >= length) || `No mínimo ${length} caracteres`

const maxValue = length => v => (v >= 10 && v <= length) || `Digite um valor entre 10 e ${length}`

const rangeValue = (min, max) => v => (v >= min && v <= max) || `Digite um valor entre ${min} e ${max}`

const validCPF = v => {
  const cpf = (v || '').replace(/\.|-/g, '')
  const message = 'CPF deve ser válido'
  let numeros; let digitos; let soma; let i; let resultado; let
    digitosIguais
  digitosIguais = 1
  if (cpf.length < 11) return message
  for (i = 0; i < cpf.length - 1; i++) {
    if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
      digitosIguais = 0
      break
    }
  }
  if (!digitosIguais) {
    numeros = cpf.substring(0, 9)
    digitos = cpf.substring(9)
    soma = 0
    for (i = 10; i > 1; i--) {
      soma += numeros.charAt(10 - i) * i
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== parseInt(digitos.charAt(0), 10)) return message
    numeros = cpf.substring(0, 10)
    soma = 0
    for (i = 11; i > 1; i--) {
      soma += numeros.charAt(11 - i) * i
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== parseInt(digitos.charAt(1), 10)) return message
    return true
  }

  return message
}

const validCNPJ = value => {
  const cnpj = (value || '').replace(/[^\d]+/g, '')

  const message = 'CNPJ deve ser válido'

  if (cnpj === '') return message

  if (cnpj.length !== 14) return message

  // Elimina CNPJs invalidos conhecidos
  if (cnpj === '00000000000000'
      || cnpj === '11111111111111'
      || cnpj === '22222222222222'
      || cnpj === '33333333333333'
      || cnpj === '44444444444444'
      || cnpj === '55555555555555'
      || cnpj === '66666666666666'
      || cnpj === '77777777777777'
      || cnpj === '88888888888888'
      || cnpj === '99999999999999') { return message }

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== parseInt(digitos.charAt(0), 10)) {
    return message
  }

  tamanho += 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== parseInt(digitos.charAt(1), 10)) {
    return message
  }

  return true
}

const validEmail = v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'E-mail deve ser válido'

export default {
  data: () => ({
    requiredRules: [
      required,
    ],
    requiredBoolean: [
      boolean,
    ],
    emailRules: [
      required,
      v => validEmail(v),
    ],
    emailOnlyRules: [
      v => (!v || validEmail(v) || 'E-mail deve ser válido'),
    ],
    urlRules: [
      required,
      v => /^(www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+(\.{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(v) || 'O campo deve ser uma url válida',
    ],
    maxLength100: [
      required,
      maxLength(100),
    ],
    maxLength50: [
      required,
      maxLength(50),
    ],
    maxLength250: [
      required,
      maxLength(250),
    ],
    maxLength500: [
      required,
      maxLength(500),
    ],
    maxLength1400: [
      required,
      maxLength(1400),
    ],
    maxLength8000: [
      required,
      maxLength(8000),
    ],
    cpfRules: [
      required,
      v => validCPF(v),
    ],
    cnpjRules: [
      required,
      v => validCNPJ(v),
    ],
    maxValue90: [
      required,
      maxValue(90),
    ],
    maxValue60: [
      required,
      maxValue(60),
    ],
    maxValue30: [
      required,
      maxValue(30),
    ],
    maxDayMediation: [
      required,
      rangeValue(20, 60),
    ],
    multipleRules: [
      v => (v && ((Array.isArray(v) && v.length > 0) || Object.keys(v).length > 0)) || 'Campo obrigatório',
    ],
    phoneRules: [
      required,
      v => /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(v) || 'Digite um número de telefone/celular válido',
    ],
    mobilePhoneRules: [
      required,
      v => /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(v) || 'Digite um número de celular válido',
    ],
    cepRules: [
      v => {
        let response
        if (typeof v === 'object') {
          response = (v && v.zipCode && v.zipCode.length > 0) || 'Campo obrigatório'
        } if (typeof v === 'string') {
          response = (v && v.length > 0) || 'Campo obrigatório'
        }

        return response
      },
    ],
    avatarRules: [
      value => !value || value.size < 2000000 || 'Tamanho da imagem deve ter menos de 2MB!',
    ],
    percentageRules: [
      v => (v >= 0 && v <= 100 && v !== '') || 'Digite um valor percentual',
    ],
    dateRules: [
      required,
      // v => /^(0[1-9]|[12][0-9]|3[01])(?:(0[1-9]|1[012])(201)[2-9]{1})$/g.test(v) || 'A data deve ser válida'
    ],
    numberRules: [
      required,
      v => /^\d+$/g.test(v) || 'Digite um número válido',
    ],
    currencyRules: [
      required,
      v => v !== '0,00' || 'Campo obrigatório',
    ],
    environmentRules: [
      required,
      v => v !== 'live' || v !== 'test' || 'Valores aceitos são "live" ou "test"',
    ],
    fullnameRules: [
      required,
      // v => /^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$/g.test(v) || 'Campo deve possuir nome e sobrenome'
    ],
    fileRules: [
      required,
      v => (v && v.size > 0) || 'Campo obrigatório',
    ],
    passwordRules: [
      required,
      minLength(8),
      v => /[A-Z]/.test(v) || 'Sua senha deve conter ao menos um caracter maiúsculo',
      v => /\d/.test(v) || 'Sua senha deve conter ao menos um caracter numérico',
    ],
    documentRules: [
      required,
      v => {
        let response = false
        const document = v?.replace(/\D/g, '')

        if (/^[0-9]*$/.test(document)) {
          response = 'CPF ou CPNJ incompleto'
          if (document.length <= 11) {
            response = validCPF(document)
          } else {
            response = validCNPJ(document)
          }
        }

        return response
      },
    ],
    documentOrEmailRules: [
      required,
      v => {
        let response = false

        if (/^[0-9]*$/.test(v)) {
          response = 'CPF ou CPNJ incompleto'
          if (v.length >= 11 && v.length < 14) {
            response = validCPF(v)
          } else if (v.length >= 14) {
            response = validCNPJ(v)
          }
        } else {
          response = validEmail(v)
        }

        return response
      },
    ],
    pixRandomKeyRules: [
      required,
      v => /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(v) || 'Digite uma Chave Aleatória PIX válida',
    ],
  }),
}
