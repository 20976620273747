export default {
  path: 'cdas',
  name: 'Cdas',
  component: () => import(/* webpackChunkName: "Cdas" */ './Index.vue'),
  meta: {
    title: 'CDA',
    subject: 'Cda',
    action: 'list',
    newTitle: 'Adicionar Cda',
    newRoute: 'CdaNewMultiple',
  },
  children: [
    {
      path: 'criar-multiplos',
      name: 'CdaNewMultiple',
      component: () => import(/* webpackChunkName: "Cdas" */'./NewMultiple.vue'),
      meta: { title: 'Múltiplas CDAs' },
    },
    {
      path: ':taxId',
      name: 'CdaShow',
      component: () => import(/* webpackChunkName: "Cdas" */'./Show.vue'),
      meta: { title: 'Cadastro Tributário' },
    },
  ],
}
